
import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import GuestLayout from "layouts/Guest.js";
import AdminLayout from "layouts/Admin.js";

import Login from "views/pages/Login";
import Dashboard from "views/pages/Dashboard";
import ApurationsIndex from "views/pages/Apurations/index";
import ApurationsShow from "views/pages/Apurations/show";
import OperationsIndex from "views/pages/Operations";
import OperationsForm from "views/pages/Operations/form";
import BrokerageNotesForm from "views/pages/BrokerageNote/form";
import ResultsIndex from "views/pages/Results";
import WalletIndex from "views/pages/Wallet";
import ReportsIndex from "views/pages/Reports";
import UserConfig from "views/pages/User/Config";
import UserInitialCustody from "views/pages/User/InitialCustody";

import { isAuthenticated } from "./services/auth";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
            )
        }
    />
);

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" render={(props) => <GuestLayout><Login {...props} /></GuestLayout>} />
            <Route exact path="/login" render={(props) => <GuestLayout><Login {...props} /></GuestLayout>} />
            <PrivateRoute exact path="/admin" component={(props) => <AdminLayout><ApurationsIndex {...props} /></AdminLayout>} />
            <PrivateRoute exact path="/admin/brokerage-notes/create" component={(props) => <AdminLayout><BrokerageNotesForm {...props} /></AdminLayout>} />
            <PrivateRoute exact path="/admin/brokerage-notes/:id/edit" component={(props) => <AdminLayout><BrokerageNotesForm {...props} /></AdminLayout>} />
            <PrivateRoute exact path="/admin/dashboard" component={(props) => <AdminLayout><Dashboard {...props} /></AdminLayout>} />
            <PrivateRoute exact path="/admin/apurations" component={(props) => <AdminLayout><ApurationsIndex {...props} /></AdminLayout>} />
            <PrivateRoute exact path="/admin/apurations/:id" component={(props) => <AdminLayout><ApurationsShow {...props} /></AdminLayout>} />
            <PrivateRoute exact path="/admin/operations" component={(props) => <AdminLayout><OperationsIndex {...props} /></AdminLayout>} />
            <PrivateRoute exact path="/admin/operations/create" component={(props) => <AdminLayout><OperationsForm {...props} /></AdminLayout>} />
            <PrivateRoute exact path="/admin/operations/:id/edit" component={(props) => <AdminLayout><OperationsForm {...props} /></AdminLayout>} />    
            <PrivateRoute exact path="/admin/results" component={(props) => <AdminLayout><ResultsIndex {...props} /></AdminLayout>} />
            <PrivateRoute exact path="/admin/wallet" component={(props) => <AdminLayout><WalletIndex {...props} /></AdminLayout>} />
            <PrivateRoute exact path="/admin/reports" component={(props) => <AdminLayout><ReportsIndex {...props} /></AdminLayout>} />
            <PrivateRoute exact path="/admin/config" component={(props) => <AdminLayout><UserConfig {...props} /></AdminLayout>} />
            <PrivateRoute exact path="/admin/initial-custody" component={(props) => <AdminLayout><UserInitialCustody {...props} /></AdminLayout>} />
            <Route path="*" component={() => <h1>Page not found</h1>} />
        </Switch>
    </BrowserRouter>
);

export default Routes;
