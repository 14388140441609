import SimpleHeader from 'components/Headers/SimpleHeader.js';
// import Pagination from "components/Pagination";
import Select from 'components/Select';
import React, { Component } from 'react';
import api from 'services/api';
import { Input, Button, Card, CardBody, CardHeader, Col, Container, Row, Table, Badge } from 'reactstrap';
import * as currency from 'currency.js';
import * as moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

const cvOptions = [
    { value: 'c', label: 'Compra' },
    { value: 'v', label: 'Venda' },
];

class Apurations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countAllOperations: undefined,
            operations: [],
            filterNoteNumber: undefined,
            filterAsset: undefined,
            filterYearMonth: undefined,
            filterCV: undefined,
        };
    }

    async componentDidMount() {
        this.handleLoadOperations();
    }

    handleFormFilterSubmit = (e) => {
        e.preventDefault();
        this.handleLoadOperations();
    };

    handleFormFilterReset = (e) => {
        e.preventDefault();
        this.form.reset();
        this.setState(
            {
                filterNoteNumber: undefined,
                filterAsset: undefined,
                filterYearMonth: undefined,
                filterCV: undefined,
            },
            this.handleLoadOperations,
        );
    };

    handleLoadOperations = async () => {
        try {
            const operations = await api
                .get('/operations')
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    throw new Error('Houve algum problema ao obter as operações.');
                });
            this.setState({ operations });
            this.setState({ countAllOperations: operations.length });
        } catch (err) {
            console.log(err);
        }
    };

    applyFilters = (operation) => {
        const filterNoteNumber = this.state.filterNoteNumber;
        const filterAsset = this.state.filterAsset;
        const filterYearMonth = this.state.filterYearMonth;
        const filterCV = this.state.filterCV;
        let filter = true;
        if (filterNoteNumber && String(operation.brokerageNote.code) !== String(filterNoteNumber)) filter = false;
        if (filterAsset && String(operation.assetTicker).toUpperCase() !== String(filterAsset).toUpperCase()) filter = false;
        if (filterYearMonth && moment(operation.brokerageNote.date).format('YYYY-MM') !== String(filterYearMonth)) filter = false;
        if (filterCV && String(operation.cv) !== String(filterCV)) filter = false;
        return filter;
    };

    handleClickEditOperation = (brokerageNoteId) => {
        this.props.history.push(`/admin/brokerage-notes/${brokerageNoteId}/edit`);
    };

    handleClickDeleteOperation = async (id) => {
        await api
            .delete(`/operations/${id}`)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                throw new Error('Houve um problema ao remover a operação.');
            });
        this.props.history.go(0);
    };

    stringToColour = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = '#';
        for (let i = 0; i < 3; i++) {
            colour += ('00' + ((hash >> (i * 8)) & 0xff).toString(16)).substr(-2);
        }
        return colour;
    };

    selectFilterYearMonthOptions = () => {
        return this.state.operations
            .slice()
            .map((operation) => moment(operation.brokerageNote.date).format('YYYY-MM'))
            .filter((value, index, self) => self.indexOf(value) === index)
            .map((date) => {
                return {
                    value: date,
                    label: date.split('-').reverse().join('/'),
                };
            });
    };

    selectFilterAssetsOptions = () => {
        return this.state.operations
            .slice()
            .map((operation) => operation.assetTicker)
            .filter((value, index, self) => self.indexOf(value) === index)
            .map((asset) => {
                return {
                    value: asset,
                    label: asset,
                };
            });
    };

    render() {
        const operations = this.state.operations;
        const dates = operations.map((o) => moment(o.brokerageNote.date).format('MMMM YYYY'));
        const operationsByMonth = [...new Set(dates)].map((month, key) => ({ month, operations: [] }));

        for (const operation of operations) {
            const month = moment(operation.brokerageNote.date).format('MMMM YYYY');
            operationsByMonth.find((m) => m.month === month).operations.push(operation);
        }

        return (
            <>
                <SimpleHeader name="Operações" parentName="Painel">
                    <Button color="primary" type="button" href={`/admin/brokerage-notes/create`}>
                        Cadastrar operação
                    </Button>
                </SimpleHeader>
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <div className="card-wrapper">
                                <Card>
                                    <CardHeader>
                                        <h3 className="mb-0">Seu histórico de operações</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col lg="8">
                                                <p className="mb-0">Todas as operações de sua carteira em ordem cronológica. Use os controles abaixo para filtrar o resultado.</p>
                                            </Col>
                                        </Row>
                                        <form ref={(form) => (this.form = form)} onSubmit={this.handleFormFilterSubmit}>
                                            <Row className="py-3">
                                                <Col>
                                                    <label className="form-control-label mt-3" htmlFor="inputFilterNoteNumber">
                                                        Nota
                                                    </label>
                                                    <Input id="inputFilterNoteNumber" type="text" onKeyUp={(e) => this.setState({ filterNoteNumber: e.target.value })} />
                                                </Col>
                                                <Col>
                                                    <label className="form-control-label mt-3" htmlFor="inputFilterAsset">
                                                        Ativo
                                                    </label>
                                                    <Select
                                                        id="inputFilterAsset"
                                                        isClearable={true}
                                                        value={this.selectFilterAssetsOptions().filter((option) => option.value === this.state.filterAsset)}
                                                        options={this.selectFilterAssetsOptions()}
                                                        onChange={(e) => this.setState({ filterAsset: e?.value })}
                                                    />
                                                </Col>

                                                <Col>
                                                    <label className="form-control-label mt-3" htmlFor="selectFilterYearMonth">
                                                        Mês/Ano
                                                    </label>
                                                    <Select
                                                        id="selectFilterYearMonth"
                                                        isClearable={true}
                                                        value={this.selectFilterYearMonthOptions().filter((option) => option.value === this.state.filterYearMonth)}
                                                        options={this.selectFilterYearMonthOptions()}
                                                        onChange={(e) => this.setState({ filterYearMonth: e?.value })}
                                                    />
                                                </Col>

                                                <Col>
                                                    <label className="form-control-label mt-3" htmlFor="selectFilterCV">
                                                        Operação
                                                    </label>
                                                    <Select id="selectFilterCV" isClearable={true} value={cvOptions.filter((option) => option.value === this.state.filterCV)} options={cvOptions} onChange={(e) => this.setState({ filterCV: e?.value }, this.handleLoadOperations)} />
                                                </Col>
                                                <Col className="pt-4">
                                                    <Button className="mt-3" type="submit" hidden={true}>
                                                        Submit
                                                    </Button>
                                                    <Button className="mt-3" onClick={this.handleFormFilterReset}>
                                                        Limpar filtros
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </form>
                                        {operationsByMonth.map((month) => (
                                            <div key={month.month}>
                                                <h3 className="mt-4 text-uppercase">{month.month}</h3>
                                                <Table className="align-items-center table-flush mt-3" responsive>
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">Nota</th>
                                                            <th scope="col">Corretora</th>
                                                            <th scope="col">Data Pregão</th>
                                                            <th scope="col">Operação</th>
                                                            <th scope="col">Ativo</th>
                                                            <th scope="col">Tipo</th>
                                                            <th scope="col">Quantidade</th>
                                                            <th scope="col">Preço</th>
                                                            <th scope="col">Custos</th>
                                                            <th scope="col">Valor da Ordem</th>
                                                            <th scope="col" text-align="center">
                                                                Estoque
                                                            </th>
                                                            <th scope="col" text-align="center">
                                                                Saldo
                                                            </th>
                                                            <th scope="col" text-align="center">
                                                                Preço Médio Compra
                                                            </th>
                                                            <th scope="col" text-align="center">
                                                                Preço Médio Venda
                                                            </th>
                                                            <th scope="col" text-align="center">
                                                                Custódia Preço
                                                                <br />
                                                                Médio
                                                            </th>
                                                            <th scope="col" text-align="center">
                                                                Custódia Valor
                                                            </th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list">
                                                        {month.operations
                                                            .filter((o) => this.applyFilters(o))
                                                            .map((operation, index) => (
                                                                <tr key={index}>
                                                                    <th scope="row">{operation.brokerageNote?.code ? operation.brokerageNote.code : '-'}</th>
                                                                    <th scope="row">{operation.brokerageNote?.broker?.name ? operation.brokerageNote.broker.name : '-'}</th>
                                                                    <td>{operation.brokerageNote?.date ? moment(operation.brokerageNote.date).format('DD/MM/YYYY') : '-'}</td>
                                                                    <td className="budget">{(operation.cv === 'c' && 'Compra') || (operation.cv === 'v' && 'Venda') || (operation.cv === 'conversion' && 'Conversão') || (operation.cv === 'grouping' && 'Agrupamento')}</td>
                                                                    <td>
                                                                        <Badge className="badge-lg" style={{ backgroundColor: this.stringToColour(operation.assetTicker), fontWeight: 700, color: 'white' }}>
                                                                            {operation.assetTicker}
                                                                        </Badge>
                                                                    </td>
                                                                    <td>{(operation.type === 'swingtrade' && 'Swing Trade') || (operation.type === 'daytrade' && 'Day Trade')}</td>
                                                                    <td>{operation.quantity}</td>
                                                                    <td>{operation.price ? currency(Number(operation.price), { separator: '.', decimal: ',', symbol: 'R$ ' }).format() : ''}</td>
                                                                    <td>{operation.cost ? currency(Number(operation.cost), { separator: '.', decimal: ',', symbol: 'R$ ' }).format() : ''}</td>
                                                                    <td>{operation.consolidatedValue ? currency(Number(operation.consolidatedValue), { separator: '.', decimal: ',', symbol: 'R$ ' }).format() : ''}</td>
                                                                    <td>{operation.totalQuantity}</td>
                                                                    <td>{operation.totalValue ? currency(Number(operation.totalValue), { separator: '.', decimal: ',', symbol: 'R$ ' }).format() : ''}</td>
                                                                    <td>{operation.averagePriceBuy ? currency(Number(operation.averagePriceBuy), { separator: '.', decimal: ',', symbol: 'R$ ' }).format() : '-'}</td>
                                                                    <td>{operation.averagePriceSell ? currency(Number(operation.averagePriceSell), { separator: '.', decimal: ',', symbol: 'R$ ' }).format() : '-'}</td>
                                                                    <td>{operation.custodyAveragePrice ? currency(Number(operation.custodyAveragePrice), { separator: '.', decimal: ',', symbol: 'R$ ' }).format() : ''}</td>
                                                                    <td>{operation.custodyTotalValue ? currency(Number(operation.custodyTotalValue), { separator: '.', decimal: ',', symbol: 'R$ ' }).format() : ''}</td>
                                                                    <td>
                                                                        {operation.cv !== 'conversion' && operation.cv !== 'grouping' && (
                                                                        <>
                                                                        <Button onClick={(e) => this.handleClickEditOperation(operation.brokerageNoteId)} outline color="primary">
                                                                            Alterar
                                                                        </Button>
                                                                        <Button onClick={(e) => this.handleClickDeleteOperation(operation.id)} outline color="danger">
                                                                            Excluir
                                                                        </Button>
                                                                        </>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        ))}

                                        <hr />

                                        {/* <div className="d-flex justify-content-end">
                        <Pagination />
                    </div> */}
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Apurations;
