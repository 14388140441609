import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Badge,
  Table,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { Component } from "react";
import api from "services/api";

class Apuration extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      apuration: null,
    }
  }

  async componentDidMount() {
    const response = await api
      .get(`/apurations/${this.props.match.params?.id}`)
      .then((response) => response.data)
      .catch((error) => console.log("Houve um problema ao buscar as informações da apuração, tente novamente em alguns instantes."));
    this.setState({apuration: response});
  }

  render() {
    return (
      <>
        <SimpleHeader name={`Mês ${this.state.apuration?.month ?? ''}`} parentName="Apurações Mensais" />
        <Container className="mt-6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0">Detalhes da apuração</h3>
                  </CardHeader>
                  <CardBody>
                    <p className="mb-0">
                      <strong>Estado:</strong> <Badge>{this.state.apuration?.statusPublicText}</Badge>
                      <br />
                      <strong>Mês Referência:</strong> {this.state.apuration?.month}
                      <br />
                      <strong>Plano:</strong> {this.state.apuration?.plan}
                      <br />
                    </p>

                    <p className="mb-0">
                      Notas Enviadas? &nbsp;
                      {this.state.apuration ? (this.state.apuration.isBrokerageNotesSent ? 
                        <Badge color="primary">Sim</Badge> : <Badge color="danger">Não</Badge>
                      ) : '-'}
                      <br />
                      Relatório disponível? &nbsp;
                      {this.state.apuration ? (this.state.apuration.isReportAvailable ? 
                        <Badge color="primary">Sim</Badge> : <Badge color="danger">Não</Badge>
                      ) : '-'}
                      <br />
                    </p>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>

          {(!!this.state.apuration?.apurationTaxFiles.length || !!this.state.apuration?.rawFiles.length) && 
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    {!!this.state.apuration?.apurationTaxFiles.length && 
                    <>
                      <h3 className="mb-3">Importo à Pagar (DARF)</h3>
                      <Table className="align-items-center" responsive>
                        <thead>
                          <tr>
                            <th className="sort" data-sort="name" scope="col">
                              Arquivo
                            </th>
                            <th className="sort" data-sort="budget" scope="col">
                              Valor
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list">
                        {this.state.apuration?.apurationTaxFiles.map((file) => (
                          <tr>
                            <th scope="row">
                              <a href={file.url} target="_blank" rel="noreferrer">{file.filename}</a>
                            </th>
                            <td className="budget">R$ 25,90</td>
                          </tr>
                        ))}
                        </tbody>
                      </Table>
                    </>
                    }

                    {(!!this.state.apuration?.apurationTaxFiles.length && !!this.state.apuration?.rawFiles.length) && 
                    <hr />
                    }

                    {!!this.state.apuration?.rawFiles.length && 
                    <>
                      <h3 className="my-4">Arquivos Enviados pelo cliente</h3>
                      <Table className="align-items-center" responsive>
                        <thead>
                          <tr>
                            <th className="sort" data-sort="name" scope="col">
                              Arquivo
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list">
                          {this.state.apuration?.rawFiles.map((file) => (
                          <tr>
                            <th scope="row">
                              <a href={file.url} target="_blank" rel="noreferrer">{file.filename}</a>
                            </th>
                          </tr>
                          ))}
                        </tbody>
                      </Table>
                    </>
                    }
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          }

          {!!this.state.apuration?.reportDownloadUrl && 
          <Row>
            <div class="col">
              <h3 className="mb-3">Relatório finalizado</h3>
              <div class="d-block pb-4">
                <a
                  class="text-primary"
                  href={`${this.state.apuration?.reportDownloadUrl}/report`}
                  alt="Link para download do Relatório Atualizado"
                >
                  Clique aqui para Baixar o relatório em PDF
                </a>
              </div>

              <div>
                <iframe
                  title="Relatório finalizado"
                  src={this.state.apuration?.reportVisualizeUrl}
                  style={{
                    border: "1px solid #ccc",
                    width: "100%",
                    height: "600px",
                  }}
                ></iframe>
              </div>
            </div>
          </Row>
          }

        </Container>
      </>
    );
  }
}

export default Apuration;
