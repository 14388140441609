
import React from "react";
import { useLocation } from "react-router-dom";

import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

function Guest({ children }) {
    const location = useLocation();
    const mainContentRef = React.useRef(null);

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContentRef.current.scrollTop = 0;
        document.body.classList.add("bg-default");
        // Specify how to clean up after this effect:
        return function cleanup() {
            document.body.classList.remove("bg-default");
        };
    });

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContentRef.current.scrollTop = 0;
    }, [location]);

    return (
        <>
        <div className="main-content" ref={mainContentRef}>
            <AuthNavbar />
            {children}
        </div>
        <AuthFooter />
        </>
    );
}

export default Guest;
