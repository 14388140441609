import axios from 'axios';
import { getToken } from './auth';
import { logout } from 'services/auth';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((config) => {
    const token = getToken();
    // console.log('token: ', token);
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            logout();
            window.location.reload();
        }
        return error;
    },
);

export default api;
