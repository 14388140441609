/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Table,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import InitialCustodyDialog from "./components/InitialCustodyDialog";

function InitialCustody() {
  const [isOpen, setIsOpen] = React.useState(false);

  const openNewCustodyForm = () => {
    setIsOpen(true);
  };

  return (
    <>
      {isOpen && <InitialCustodyDialog />}
      <SimpleHeader name="Custódia Inicial" parentName="Meus Dados" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Cadastre sua custódia inicial</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg="8">
                      <p className="mb-0">
                        Nesta seção, você deve informar sua custódia inicial.
                        Cadastre seus ativos e os preços médios pelo custo total
                        de aquisição, ou seja, incluindo as taxas cobradas pela
                        B3 e pela Corretora. O cadastramento de ativos pelo
                        preço de compra (preço pago na tela do homebroker),
                        acarreta em distorções no ganho de capital que podem
                        levar ao pagamento de impostos maiores que os devidos.
                      </p>
                    </Col>
                  </Row>

                  <Table
                    className="align-items-center table-flush mt-3"
                    responsive
                  >
                    <thead>
                      <tr>
                        <th className="sort" data-sort="name" scope="col">
                          Corretora
                        </th>
                        <th className="sort" data-sort="budget" scope="col">
                          Ativo
                        </th>
                        <th className="sort" data-sort="status" scope="col">
                          Tipo
                        </th>
                        <th className="sort" data-sort="completion" scope="col">
                          Quantidade
                        </th>
                        <th className="sort" data-sort="completion" scope="col">
                          Preço Médio
                        </th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody className="list">
                      <tr>
                        <th scope="row">XP</th>
                        <td className="budget">PETR4</td>
                        <td>Vista / Leilão</td>
                        <td>1500</td>
                        <td>R$ 25,69</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              color=""
                              role="button"
                              size="sm"
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Remover
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <div class="mt-4">
                    <Button
                      color="primary"
                      type="button"
                      onClick={openNewCustodyForm}
                    >
                      Cadastrar ativo
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default InitialCustody;
