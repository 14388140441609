import SimpleHeader from 'components/Headers/SimpleHeader.js';
// import Pagination from "components/Pagination";
import Select from 'components/Select';
import React, { Component } from 'react';
// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    // Input,
    Row,
    Table,
    CardTitle,
} from 'reactstrap';
// import ResultsHeader from "./ResultsHeader";
import api from 'services/api';
import * as moment from 'moment';
import * as currency from 'currency.js';

const cvOptions = [
    { value: 'c', label: 'Compra' },
    { value: 'v', label: 'Venda' },
];

const marketTypeOptions = [
    { value: 'common', label: 'Vista / Leilão' },
    { value: 'common_term', label: 'Termo' },
    { value: 'common_option_buy', label: 'Opç. Compra' },
    { value: 'common_option_sell', label: 'Opç. Venda' },
    { value: 'common_option_due', label: 'Exer. Opção' },
    { value: 'common_fii', label: 'Fundos imobiliários' },
    { value: 'future', label: 'Futuro & BM&F' },
];

class Results extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operations: [],
            // selectFilterYearMonthSelected: undefined,
            filterNoteNumber: undefined,
            filterAsset: undefined,
            filterYearMonth: undefined,
            filterCV: undefined,
        };
    }

    async componentDidMount() {
        this.handleLoadOperations();
    }

    handleLoadOperations = async () => {
        try {
            // const filters = this.getFiltersValues();
            const operations = await api
                .get('/operations')
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    throw new Error('Houve algum problema ao obter as operações.');
                });
            if (!operations) {
                throw new Error('Não foram encontradas operações.');
            }
            this.setState({ operations });
        } catch (err) {
            console.log(err);
        }
    };

    getFiltersValues = () => {
        return {
            noteNumber: this.state.filterNoteNumber,
            asset: this.state.filterAsset,
            year: this.state.filterYearMonth?.split('-')[0],
            month: this.state.filterYearMonth?.split('-')[1],
            cv: this.state.filterCV,
        };
    };

    selectFilterYearMonthOptions = () => {
        return this.state.operations
            .slice()
            .map((operation) => moment(operation.brokerageNote.date).format('YYYY-MM'))
            .filter((value, index, self) => self.indexOf(value) === index)
            .map((date) => {
                return {
                    value: date,
                    label: date.split('-').reverse().join('/'),
                };
            });
    };

    selectFilterAssetsOptions = () => {
        return this.state.operations
            .slice()
            .map((operation) => operation.assetTicker)
            .filter((value, index, self) => self.indexOf(value) === index)
            .map((asset) => {
                return {
                    value: asset,
                    label: asset,
                };
            });
    };

    getCountOperations = () => {
        return this.state.operations
            .slice()
            .filter(
                (operation) =>
                    (this.state.filterYearMonth ? moment(operation.brokerageNote.date).format('YYYY-MM') === this.state.filterYearMonth : true) &&
                    (this.state.filterAsset ? operation.assetTicker === this.state.filterAsset : true) &&
                    (this.state.filterCV ? operation.cv === this.state.filterCV : true),
            ).length;
    };

    getProfitSwingTrade = () => {
        return (
            this.state.operations
                .slice()
                .filter(
                    (operation) =>
                        operation.type === 'swingtrade' &&
                        operation.balance > 0 &&
                        (this.state.filterYearMonth ? moment(operation.brokerageNote.date).format('YYYY-MM') === this.state.filterYearMonth : true) &&
                        (this.state.filterAsset ? operation.assetTicker === this.state.filterAsset : true) &&
                        operation.cv === 'v',
                )
                .reduce((sum, operation) => sum + operation.balance, 0) - this.getProfitTaxFree()
        );
    };

    // operation.asset.kind === 'stocks' &&
    // operation.quantity * operation.price < 20000

    getLossSwingTrade = () => {
        // const isTaxFree =
        // (currentOperation.cv === 'v' && currentOperation.asset.kind === 'stocks') ||
        //     String(currentOperation.asset.ticker).toLowerCase() === 'gshp3' ||
        //     String(currentOperation.asset.ticker).toLowerCase() === 'agro3' ||
        //     String(currentOperation.asset.ticker).toLowerCase() === 'crde3' ||
        //     String(currentOperation.asset.ticker).toLowerCase() === 'nutr3' ||
        //     String(currentOperation.asset.ticker).toLowerCase() === 'frta3';
        // const taxFree = isTaxFree ? currentOperation.quantity * currentOperation.price : 0;
        return this.state.operations
            .slice()
            .filter(
                (operation) =>
                    operation.type === 'swingtrade' &&
                    operation.balance < 0 &&
                    (this.state.filterYearMonth ? moment(operation.brokerageNote.date).format('YYYY-MM') === this.state.filterYearMonth : true) &&
                    (this.state.filterAsset ? operation.assetTicker === this.state.filterAsset : true) &&
                    operation.cv === 'v' &&
                    !(
                        operation.asset.kind === 'stocks' &&
                        (String(operation.asset.ticker).toLowerCase() === 'gshp3' ||
                            String(operation.asset.ticker).toLowerCase() === 'agro3' ||
                            String(operation.asset.ticker).toLowerCase() === 'crde3' ||
                            String(operation.asset.ticker).toLowerCase() === 'nutr3' ||
                            String(operation.asset.ticker).toLowerCase() === 'frta3')
                    ),
            )
            .reduce((sum, operation) => sum + operation.balance, 0);
    };

    getProfitDayTrade = () => {
        return this.state.operations
            .slice()
            .filter(
                (operation) =>
                    operation.type === 'daytrade' &&
                    operation.balance > 0 &&
                    (this.state.filterYearMonth ? moment(operation.brokerageNote.date).format('YYYY-MM') === this.state.filterYearMonth : true) &&
                    (this.state.filterAsset ? operation.assetTicker === this.state.filterAsset : true) &&
                    operation.cv === 'v',
            )
            .reduce((sum, operation) => sum + operation.balance, 0);
    };

    getLossDayTrade = () => {
        return this.state.operations
            .slice()
            .filter(
                (operation) =>
                    operation.type === 'daytrade' &&
                    operation.balance < 0 &&
                    (this.state.filterYearMonth ? moment(operation.brokerageNote.date).format('YYYY-MM') === this.state.filterYearMonth : true) &&
                    (this.state.filterAsset ? operation.assetTicker === this.state.filterAsset : true) &&
                    operation.cv === 'v' &&
                    !(
                        operation.asset.kind === 'stocks' &&
                        (String(operation.asset.ticker).toLowerCase() === 'gshp3' ||
                            String(operation.asset.ticker).toLowerCase() === 'agro3' ||
                            String(operation.asset.ticker).toLowerCase() === 'crde3' ||
                            String(operation.asset.ticker).toLowerCase() === 'nutr3' ||
                            String(operation.asset.ticker).toLowerCase() === 'frta3')
                    ),
            )
            .reduce((sum, operation) => sum + operation.balance, 0);
    };

    getProfitFiisTrade = () => {
        return this.state.operations
            .slice()
            .filter(
                (operation) =>
                    operation.asset.kind === 'fiis' &&
                    operation.balance > 0 &&
                    (this.state.filterYearMonth ? moment(operation.brokerageNote.date).format('YYYY-MM') === this.state.filterYearMonth : true) &&
                    (this.state.filterAsset ? operation.assetTicker === this.state.filterAsset : true) &&
                    operation.cv === 'v',
            )
            .reduce((sum, operation) => sum + operation.balance, 0);
    };

    getLossFiisTrade = () => {
        return this.state.operations
            .slice()
            .filter(
                (operation) =>
                    operation.asset.kind === 'fiis' &&
                    operation.balance < 0 &&
                    (this.state.filterYearMonth ? moment(operation.brokerageNote.date).format('YYYY-MM') === this.state.filterYearMonth : true) &&
                    (this.state.filterAsset ? operation.assetTicker === this.state.filterAsset : true) &&
                    operation.cv === 'v' &&
                    !(
                        operation.asset.kind === 'stocks' &&
                        (String(operation.asset.ticker).toLowerCase() === 'gshp3' ||
                            String(operation.asset.ticker).toLowerCase() === 'agro3' ||
                            String(operation.asset.ticker).toLowerCase() === 'crde3' ||
                            String(operation.asset.ticker).toLowerCase() === 'nutr3' ||
                            String(operation.asset.ticker).toLowerCase() === 'frta3')
                    ),
            )
            .reduce((sum, operation) => sum + operation.balance, 0);
    };

    getProfitTaxFree = () => {
        const operations = this.state.operations;
        let operationsByMonth = [];
        for (const currentOperation of operations) {
            const currentOperationYear = moment(currentOperation.brokerageNote.date).format('YYYY');
            const currentOperationMonth = moment(currentOperation.brokerageNote.date).format('MM');
            const finded = operationsByMonth.find((row) => row.year === currentOperationYear && row.month === currentOperationMonth);
            const isTaxFree =
                (currentOperation.cv === 'v' && currentOperation.asset.kind === 'stocks') ||
                String(currentOperation.asset.ticker).toLowerCase() === 'gshp3' ||
                String(currentOperation.asset.ticker).toLowerCase() === 'agro3' ||
                String(currentOperation.asset.ticker).toLowerCase() === 'crde3' ||
                String(currentOperation.asset.ticker).toLowerCase() === 'nutr3' ||
                String(currentOperation.asset.ticker).toLowerCase() === 'frta3';
            const taxFree = isTaxFree ? currentOperation.quantity * currentOperation.price : 0;
            if (finded) {
                finded.totalSales += taxFree;
                finded.operations.push(currentOperation);
            } else {
                operationsByMonth.push({
                    year: currentOperationYear,
                    month: currentOperationMonth,
                    totalSales: taxFree,
                    operations: [currentOperation],
                });
            }
        }
        let profitTaxFree = 0;
        for (const month of operationsByMonth) {
            profitTaxFree += month.operations
                .slice()
                .filter(
                    (operation) =>
                        operation.type === 'swingtrade' &&
                        operation.asset.kind === 'stocks' &&
                        (this.state.filterYearMonth ? moment(operation.brokerageNote.date).format('YYYY-MM') === this.state.filterYearMonth : true) &&
                        (this.state.filterAsset ? operation.assetTicker === this.state.filterAsset : true) &&
                        operation.cv === 'v' &&
                        operation.balance > 0 &&
                        (month.totalSales < 20000 ||
                            String(operation.asset.ticker).toLowerCase() === 'gshp3' ||
                            String(operation.asset.ticker).toLowerCase() === 'agro3' ||
                            String(operation.asset.ticker).toLowerCase() === 'crde3' ||
                            String(operation.asset.ticker).toLowerCase() === 'nutr3' ||
                            String(operation.asset.ticker).toLowerCase() === 'frta3'),
                )
                .reduce((sum, operation) => sum + operation.balance, 0);
        }
        return profitTaxFree;
    };

    handleFormFilterSubmit = (e) => {
        e.preventDefault();
        this.handleLoadOperations();
    };

    handleFormFilterReset = (e) => {
        e.preventDefault();
        this.form.reset();
        this.setState(
            {
                filterYearMonth: undefined,
                filterAsset: undefined,
                filterCV: undefined,
            },
            this.handleLoadOperations,
        );
    };

    stringToColour = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = '#';
        for (let i = 0; i < 3; i++) {
            colour += ('00' + ((hash >> (i * 8)) & 0xff).toString(16)).substr(-2);
        }
        return colour;
    };

    render() {
        return (
            <>
                <SimpleHeader name="Resultados" parentName="Painel" />
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <div className="card-wrapper">
                                <Card>
                                    <CardHeader>
                                        <h3 className="mb-0">Memória de Cálculo — Lucros e Prejuízos</h3>
                                        <p className="mb-0">Verifique aqui a memória de cálculo de cada ativo seu e descubra seus lucros e prejuízos.</p>
                                    </CardHeader>
                                    <CardBody>
                                        <form ref={(form) => (this.form = form)} onSubmit={this.handleFormFilterSubmit}>
                                            <Row>
                                                <Col xl="2">
                                                    <label className="form-control-label mt-3" htmlFor="validationCustom03">
                                                        Mês/Ano
                                                    </label>
                                                    <Select
                                                        id="selectFilterYearMonth"
                                                        isClearable={true}
                                                        value={this.selectFilterYearMonthOptions().filter((option) => option.value === this.state.filterYearMonth)}
                                                        options={this.selectFilterYearMonthOptions()}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                filterYearMonth: e?.value,
                                                            })
                                                        }
                                                    />
                                                </Col>

                                                <Col xl="2">
                                                    <label className="form-control-label mt-3" htmlFor="validationCustom03">
                                                        Papel
                                                    </label>
                                                    <Select
                                                        id="inputFilterAsset"
                                                        isClearable={true}
                                                        value={this.selectFilterAssetsOptions().filter((option) => option.value === this.state.filterAsset)}
                                                        options={this.selectFilterAssetsOptions()}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                filterAsset: e?.value,
                                                            })
                                                        }
                                                    />
                                                </Col>

                                                <Col xl="2">
                                                    <label className="form-control-label mt-3" htmlFor="validationCustom03">
                                                        Operação
                                                    </label>
                                                    <Select
                                                        id="selectFilterCV"
                                                        isClearable={true}
                                                        value={cvOptions.filter((option) => option.value === this.state.filterCV)}
                                                        options={cvOptions}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                filterCV: e?.value,
                                                            })
                                                        }
                                                    />
                                                </Col>

                                                {/* <Col xl="2">
                        <label
                          className="form-control-label mt-3"
                          htmlFor="validationCustom03"
                        >
                          Corretora
                        </label>
                        <Select
                          defaultValue="1"
                          options={[
                          ]}
                        />
                      </Col> */}

                                                <Col className="pt-4">
                                                    <Button className="mt-3" type="submit" hidden={true}>
                                                        Submit
                                                    </Button>
                                                    <Button className="mt-3" onClick={this.handleFormFilterReset}>
                                                        Limpar filtros
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </form>

                                        <hr />

                                        <Row>
                                            <Col md="6" xl="3">
                                                <Card className="card-stats">
                                                    <CardBody>
                                                        <Row>
                                                            <div className="col">
                                                                <CardTitle tag="h5" className="text-uppercase mb-0">
                                                                    Resultados swing-trade
                                                                </CardTitle>
                                                                <span className="h3 text-gray font-weight-bold mb-0">
                                                                    <small>Lucro</small>&nbsp;
                                                                    <span className="text-success">
                                                                        {currency(this.getProfitSwingTrade(), {
                                                                            separator: '.',
                                                                            decimal: ',',
                                                                            symbol: 'R$ ',
                                                                        }).format()}
                                                                    </span>
                                                                </span>
                                                                <br />
                                                                <span className="h3 text-gray font-weight-bold mb-0">
                                                                    <small>Prejuízo</small>&nbsp;
                                                                    <span className="text-danger">
                                                                        {currency(this.getLossSwingTrade(), {
                                                                            separator: '.',
                                                                            decimal: ',',
                                                                            symbol: 'R$ ',
                                                                        }).format()}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            <Col className="col-auto">
                                                                <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                                                    <i className="ni ni-bold-down" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col md="6" xl="3">
                                                <Card className="card-stats">
                                                    <CardBody>
                                                        <Row>
                                                            <div className="col">
                                                                <CardTitle tag="h5" className="text-uppercase mb-0">
                                                                    Resultados day-trade
                                                                </CardTitle>
                                                                <span className="h3 text-gray font-weight-bold mb-0">
                                                                    <small>Lucro</small>&nbsp;
                                                                    <span className="text-success">
                                                                        {currency(this.getProfitDayTrade(), {
                                                                            separator: '.',
                                                                            decimal: ',',
                                                                            symbol: 'R$ ',
                                                                        }).format()}
                                                                    </span>
                                                                </span>
                                                                <br />
                                                                <span className="h3 text-gray font-weight-bold mb-0">
                                                                    <small>Prejuízo</small>&nbsp;
                                                                    <span className="text-danger">
                                                                        {currency(this.getLossDayTrade(), {
                                                                            separator: '.',
                                                                            decimal: ',',
                                                                            symbol: 'R$ ',
                                                                        }).format()}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            <Col className="col-auto">
                                                                <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                                                    <i className="ni ni-bold-down" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col md="6" xl="3">
                                                <Card className="card-stats">
                                                    <CardBody>
                                                        <Row>
                                                            <div className="col">
                                                                <CardTitle tag="h5" className="text-uppercase mb-0">
                                                                    Resultados FIIS
                                                                </CardTitle>
                                                                <span className="h3 text-gray font-weight-bold mb-0">
                                                                    <small>Lucro</small>&nbsp;
                                                                    <span className="text-success">
                                                                        {currency(this.getProfitFiisTrade(), {
                                                                            separator: '.',
                                                                            decimal: ',',
                                                                            symbol: 'R$ ',
                                                                        }).format()}
                                                                    </span>
                                                                </span>
                                                                <br />
                                                                <span className="h3 text-gray font-weight-bold mb-0">
                                                                    <small>Prejuízo</small>&nbsp;
                                                                    <span className="text-danger">
                                                                        {currency(this.getLossFiisTrade(), {
                                                                            separator: '.',
                                                                            decimal: ',',
                                                                            symbol: 'R$ ',
                                                                        }).format()}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            <Col className="col-auto">
                                                                <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                                                    <i className="ni ni-bold-down" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col md="6" xl="3">
                                                <Card className="card-stats">
                                                    <CardBody>
                                                        <Row>
                                                            <div className="col">
                                                                <CardTitle tag="h5" className="text-uppercase mb-0">
                                                                    Lucro isento de imposto
                                                                </CardTitle>
                                                                <span className="h2 font-weight-bold mb-0">
                                                                    {currency(this.getProfitTaxFree(), {
                                                                        separator: '.',
                                                                        decimal: ',',
                                                                        symbol: 'R$ ',
                                                                    }).format()}
                                                                </span>
                                                            </div>
                                                            <Col className="col-auto">
                                                                <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                                                                    <i className="ni ni-money-coins" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            {/* <Col md="6" xl="3">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Lucro não isento
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">R$ {this.state.profitNotExempt ?? '-'}</span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                <i className="ni ni-money-coins" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col> */}
                                            <Col md="6" xl="3">
                                                <Card className="card-stats">
                                                    <CardBody>
                                                        <Row>
                                                            <div className="col">
                                                                <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                                                                    Operações
                                                                </CardTitle>
                                                                <span className="h2 font-weight-bold mb-0">{this.getCountOperations() ?? '-'}</span>
                                                            </div>
                                                            <Col className="col-auto">
                                                                <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                                                                    <i className="ni ni-bullet-list-67" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>

                                        <Table className="align-items-center table-flush mt-3" responsive>
                                            <thead>
                                                <tr>
                                                    <th className="sort" data-sort="budget" scope="col">
                                                        Data
                                                    </th>
                                                    <th className="sort" data-sort="status" scope="col">
                                                        Papel
                                                    </th>
                                                    <th className="sort" data-sort="completion" scope="col">
                                                        C/V
                                                    </th>
                                                    <th className="sort" data-sort="completion" scope="col">
                                                        Tipo
                                                    </th>
                                                    <th className="sort" data-sort="completion" scope="col">
                                                        Mercado
                                                    </th>
                                                    <th className="sort" data-sort="completion" scope="col">
                                                        QTD
                                                    </th>
                                                    <th className="sort" data-sort="completion" scope="col">
                                                        Preço
                                                    </th>
                                                    <th className="sort" data-sort="completion" scope="col">
                                                        Custos
                                                    </th>
                                                    <th className="sort" data-sort="completion" scope="col">
                                                        Estoque
                                                    </th>
                                                    <th className="sort" data-sort="completion" scope="col">
                                                        Saldo
                                                    </th>
                                                    <th className="sort" data-sort="completion" scope="col">
                                                        Preço médio
                                                        <br />
                                                        compra
                                                    </th>
                                                    <th className="sort" data-sort="completion" scope="col">
                                                        Preço médio
                                                        <br />
                                                        venda
                                                    </th>
                                                    <th className="sort" data-sort="completion" scope="col">
                                                        Lucro/Prejuízo
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="list">
                                                {this.state.operations
                                                    ?.filter(
                                                        (operation) =>
                                                            (this.state.filterYearMonth ? moment(operation.brokerageNote.date).format('YYYY-MM') === this.state.filterYearMonth : true) &&
                                                            (this.state.filterAsset ? operation.assetTicker === this.state.filterAsset : true) &&
                                                            (this.state.filterCV ? operation.cv === this.state.filterCV : true),
                                                    )
                                                    .map((operation, index) => (
                                                        <tr key={index + '-operation'}>
                                                            <td className="budget">{moment(operation.brokerageNote.date).format('DD/MM/YYYY')}</td>
                                                            <td>
                                                                <Badge
                                                                    className="badge-lg"
                                                                    style={{
                                                                        backgroundColor: this.stringToColour(operation.assetTicker),
                                                                        fontWeight: 700,
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    {operation.assetTicker}
                                                                </Badge>
                                                            </td>
                                                            <td>{(operation.cv === 'c' && 'Compra') || (operation.cv === 'v' && 'Venda') || (operation.cv === 'conversion' && 'Conversão')}</td>
                                                            <td>{(operation.type === 'swingtrade' && 'Swing Trade') || (operation.type === 'daytrade' && 'Day Trade') || (operation.type === 'conversion' && 'Conversão') || (operation.type === 'grouping' && 'Agrupamento')}</td>
                                                            <td>{operation.marketType ? marketTypeOptions.find((option) => option.value === operation.marketType)?.label : '-'}</td>
                                                            <td>{operation.quantity}</td>
                                                            <td>
                                                                {currency(Number(operation.price), {
                                                                    separator: '.',
                                                                    decimal: ',',
                                                                    symbol: 'R$ ',
                                                                }).format()}
                                                            </td>
                                                            <td>
                                                                {operation.cost
                                                                    ? currency(Number(operation.cost), {
                                                                          separator: '.',
                                                                          decimal: ',',
                                                                          symbol: 'R$ ',
                                                                      }).format()
                                                                    : ''}
                                                            </td>
                                                            <td>{operation.totalQuantity}</td>
                                                            <td>
                                                                {operation.consolidatedValue
                                                                    ? currency(Number(operation.consolidatedValue), {
                                                                          separator: '.',
                                                                          decimal: ',',
                                                                          symbol: 'R$ ',
                                                                      }).format()
                                                                    : ''}
                                                            </td>
                                                            <td>
                                                                {operation.averagePriceBuy
                                                                    ? currency(Number(operation.averagePriceBuy), {
                                                                          separator: '.',
                                                                          decimal: ',',
                                                                          symbol: 'R$ ',
                                                                      }).format()
                                                                    : '-'}
                                                            </td>
                                                            <td>
                                                                {operation.averagePriceSell
                                                                    ? currency(Number(operation.averagePriceSell), {
                                                                          separator: '.',
                                                                          decimal: ',',
                                                                          symbol: 'R$ ',
                                                                      }).format()
                                                                    : '-'}
                                                            </td>
                                                            <td className={operation.balance > 0 ? 'text-success' : operation.balance < 0 ? 'text-danger' : 'text-default'}>
                                                                {operation.balance
                                                                    ? currency(Number(operation.balance), {
                                                                          separator: '.',
                                                                          decimal: ',',
                                                                          symbol: 'R$ ',
                                                                      }).format()
                                                                    : '-'}
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </Table>

                                        {/* <hr /> */}

                                        {/* <div className="d-flex justify-content-end">
                    <Pagination />
                  </div> */}
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Results;
