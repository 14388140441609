import React, { useEffect, useState } from 'react';
// import ReactDatetime from 'react-datetime';
import { Button, Card, CardHeader, CardBody, Form, Input, Container, Row, Col } from 'reactstrap';
import SimpleHeader from 'components/Headers/SimpleHeader.js';

import api from 'services/api';

function UserConfig() {
    // const [initialDateOperations, setInitialDateOperations] = React.useState('');
    const [initialDateOperations, setInitialDateOperations] = useState('');
    const [darfLessThanTen, setDarfLessThanTen] = useState('');
    const [accumulatedLossesDayTrade, setAccumulatedLossesDayTrade] = useState('');
    const [accumulatedLossesSwingTrade, setAccumulatedLossesSwingTrade] = useState('');
    const [accumulatedLossesRealEstateFunds, setAccumulatedLossesRealEstateFunds] = useState('');
    const [irrfAccumulatedDayTrade, setIrrfAccumulatedDayTrade] = useState('');
    const [irrfAccumulatedSwingTrade, setIrrfAccumulatedSwingTrade] = useState('');
    const [irrfAccumulatedRealEstateFunds, setIrrfAccumulatedRealEstateFunds] = useState('');
    const [error, setError] = useState('');
    // const [accumulatedLossesDayTrade, setAccumulatedLossesDayTrade] = React.useState('');
    // const [accumulatedLossesSwingTrade, setAccumulatedLossesSwingTrade] = React.useState('');
    // const [accumulatedLossesRealEstateFunds, setAccumulatedLossesRealEstateFunds] = React.useState('');
    // const [irrfAccumulatedDayTrade, setIrrfAccumulatedDayTrade] = React.useState('');
    // const [irrfAccumulatedSwingTrade, setIrrfAccumulatedSwingTrade] = React.useState('');
    // const [irrfAccumulatedRealEstateFunds, setIrrfAccumulatedRealEstateFunds] = React.useState('');

    useEffect(() => {
        loadInitialCustody();
    }, []);

    const loadInitialCustody = async (id) => {
        try {
            const result = await api
                .get(`/initial-custody`)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    throw new Error('Houve um problema ao obter os dados da custódia inicial.');
                });
            if (!result) return false;
            const initialCustody = result;
            setInitialDateOperations(initialCustody.initialDateOperations);
            setDarfLessThanTen(initialCustody.darfLessThanTen);
            setAccumulatedLossesDayTrade(initialCustody.accumulatedLossesDayTrade);
            setAccumulatedLossesSwingTrade(initialCustody.accumulatedLossesSwingTrade);
            setAccumulatedLossesRealEstateFunds(initialCustody.accumulatedLossesRealEstateFunds);
            setIrrfAccumulatedDayTrade(initialCustody.irrfAccumulatedDayTrade);
            setIrrfAccumulatedSwingTrade(initialCustody.irrfAccumulatedSwingTrade);
            setIrrfAccumulatedRealEstateFunds(initialCustody.irrfAccumulatedRealEstateFunds);
        } catch (err) {
            console.log(err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api
                .put(`/initial-custody`, {
                    initialDateOperations,
                    darfLessThanTen,
                    accumulatedLossesDayTrade,
                    accumulatedLossesSwingTrade,
                    accumulatedLossesRealEstateFunds,
                    irrfAccumulatedDayTrade,
                    irrfAccumulatedSwingTrade,
                    irrfAccumulatedRealEstateFunds,
                })
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    throw new Error('Houve um problema ao atualizar.');
                });
            if (!response || !response.id) {
                throw new Error('Houve um problema ao atualizar.');
            }
            this.props.history.push('/admin/initial-custody');
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <>
            <SimpleHeader name="Configuração Inicial" parentName="Meus Dados" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <div className="card-wrapper">
                            <Card>
                                <CardHeader>
                                    <h3 className="mb-0">Prejuízos Acumulados e IRRF</h3>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col lg="8">
                                            <p className="mb-0">
                                                Nesta seção, você deve informar seus prejuízos acumulados. Caso você já possua uma assessoria tributária da B3, informe aqui seus prejuízos acumulados. O valor que você informar aqui afetará toda e qualquer apuração gerada pelo sistema da data inicial
                                                para frente.
                                            </p>
                                        </Col>
                                    </Row>
                                    <hr />
                                    {error && <p style={{ color: 'red' }}>{error}</p>}
                                    <Form role="form" onSubmit={handleSubmit}>
                                        <div className="form-row">
                                            <Col className="mb-3" md="4">
                                                <label className="form-control-label" htmlFor="validationCustom01">
                                                    Data inicial para todas as apurações *
                                                </label>
                                                <Input value={initialDateOperations} id="initial-date-operations" placeholder="" type="date" onChange={(e) => setInitialDateOperations(e?.target.value.split('/').reverse().join('-') ?? null)} />
                                                {/* <ReactDatetime
                                                    inputProps={{
                                                        placeholder: 'Escolha um data',
                                                        value: initialDateOperations,
                                                        onChange: (e) => setInitialDateOperations(e.target.value),
                                                    }}
                                                    required
                                                    timeFormat={false}
                                                /> */}
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label className="form-control-label" htmlFor="validationCustom02">
                                                    Valor de DARF menor que R$10,00? *
                                                </label>
                                                <Input id="validationCustom02" placeholder="0,00" type="number" step="any" value={darfLessThanTen} onChange={(e) => setDarfLessThanTen(e.target.value)} />
                                            </Col>
                                        </div>
                                        <hr />

                                        <h2>Prejuízos acumulados</h2>
                                        <div className="form-row">
                                            <Col className="mb-3" md="4">
                                                <label className="form-control-label" htmlFor="validationCustom03">
                                                    Day Trade *
                                                </label>
                                                <Input id="validationCustom03" placeholder="0,00" type="number" step="any" value={accumulatedLossesDayTrade} onChange={(e) => setAccumulatedLossesDayTrade(e.target.value)} />
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label className="form-control-label" htmlFor="validationCustom03">
                                                    Swing Trade *
                                                </label>
                                                <Input id="validationCustom03" placeholder="0,00" type="number" step="any" value={accumulatedLossesSwingTrade} onChange={(e) => setAccumulatedLossesSwingTrade(e.target.value)} />
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label className="form-control-label" htmlFor="validationCustom03">
                                                    Fundos Imobiliários *
                                                </label>
                                                <Input id="validationCustom03" placeholder="0,00" type="number" step="any" value={accumulatedLossesRealEstateFunds} onChange={(e) => setAccumulatedLossesRealEstateFunds(e.target.value)} />
                                            </Col>
                                        </div>

                                        <hr />

                                        <h2>IRRF acumulado</h2>
                                        <div className="form-row">
                                            <Col className="mb-3" md="4">
                                                <label className="form-control-label" htmlFor="validationCustom03">
                                                    Day Trade *
                                                </label>
                                                <Input id="validationCustom03" placeholder="0,00" type="number" step="any" value={irrfAccumulatedDayTrade} onChange={(e) => setIrrfAccumulatedDayTrade(e.target.value)} />
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label className="form-control-label" htmlFor="validationCustom03">
                                                    Swing Trade *
                                                </label>
                                                <Input id="validationCustom03" placeholder="0,00" type="number" step="any" value={irrfAccumulatedSwingTrade} onChange={(e) => setIrrfAccumulatedSwingTrade(e.target.value)} />
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label className="form-control-label" htmlFor="validationCustom03">
                                                    Fundos Imobiliários *
                                                </label>
                                                <Input id="validationCustom03" placeholder="0,00" type="number" step="any" value={irrfAccumulatedRealEstateFunds} onChange={(e) => setIrrfAccumulatedRealEstateFunds(e.target.value)} />
                                            </Col>
                                        </div>

                                        <Button color="primary" type="submit">
                                            Atualizar informações
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default UserConfig;
