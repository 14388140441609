/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import Login from "views/pages/Login";
// import Apuration from "views/pages/Apurations/show";
import Apurations from "views/pages/Apurations/index";
import Dashboard from "views/pages/Dashboard";
import Operations from "views/pages/Operations";
import Results from "views/pages/Results";
import Wallet from "views/pages/Wallet";
import UserConfig from "views/pages/User/Config.js";
// import InitialCustody from "views/pages/User/InitialCustody.js";

const routes = [
  // {
  //   path: "/login",
  //   name: "Login",
  //   miniName: "L",
  //   component: Login,
  //   layout: "/auth",
  // },
  {
    path: "/dashboard",
    exact: true,
    name: "Início",
    icon: "ni ni-shop",
    component: Dashboard,
    layout: "/admin",
  },
  // {
  //   path: "/apurations/:id",
  //   name: "Detalhes da Apuração",
  //   icon: "ni ni-archive-2 text-red",
  //   component: Apuration,
  //   layout: "/admin",
  //   hideFromSidebar: true,
  // },
  {
    path: "/apurations",
    exact: true,
    name: "Apurações Mensais",
    icon: "ni ni-books",
    component: Apurations,
    layout: "/admin",
  },
  {
    path: "/operations",
    name: "Operações",
    icon: "ni ni-archive-2",
    component: Operations,
    layout: "/admin",
  },
  {
    path: "/results",
    name: "Resultados",
    icon: "ni ni-check-bold",
    component: Results,
    layout: "/admin",
  },  
  {
    path: "/wallet",
    name: "Carteira",
    icon: "ni ni-bag-17",
    component: Wallet,
    layout: "/admin",
  },  
  {
    path: "/reports",
    exact: true,
    name: "Relatórios",
    icon: "ni ni-books",
    component: Apurations,
    layout: "/admin",
  },
  {
    path: "/config",
    name: "Configuração Inicial",
    icon: "ni ni-shop",
    component: UserConfig,
    layout: "/admin",
  },
  // {
  //   collapse: true,
  //   name: "Configuração Inicial",
  //   icon: "ni ni-shop",
  //   state: "dashboardsCollapse",
  //   views: [
  //     {
  //       path: "/config",
  //       name: "Prejuízos Acumulados",
  //       miniName: "C",
  //       component: UserConfig,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/initial-custody",
  //       name: "Custódia Inicial",
  //       miniName: "I",
  //       component: InitialCustody,
  //       layout: "/admin",
  //     },
  //   ],
  // },
];

export default routes;
