/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import {
    Button,
    Card,
    // CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from 'reactstrap';

import api from 'services/api';
import { login, logout } from 'services/auth';

import AuthHeader from 'components/Headers/AuthHeader.js';

class Login extends Component {
    // const [email, password, error] = useState(false);
    // const [focusedEmail, setfocusedEmail] = useState(false);
    // const [focusedPassword, setfocusedPassword] = useState(false);

    state = {
        email: '',
        password: '',
        error: '',
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const { email, password } = this.state;
        try {
            if (!email || !password) {
                throw new Error('Preencha e-mail e senha para continuar!');
            }
            logout();
            const response = await api
                .post('/auth/login', { email, password })
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    throw new Error('Houve um problema com o login, verifique suas credenciais.');
                });
            if (!response || !response.access_token) {
                throw new Error('Houve um problema com o login, verifique suas credenciais.');
            }
            login(response.access_token);
            this.props.history.push('/admin');
        } catch (err) {
            this.setState({ error: err.message });
        }
    };

    render() {
        return (
            <>
                <AuthHeader title="Bem vindo!" lead="Use estes formulários para fazer o login." />
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        <Col lg="5" md="7">
                            <Card className="bg-secondary border-0 mb-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    {this.state.error && <p style={{ color: 'red' }}>{this.state.error}</p>}
                                    <Form role="form" onSubmit={this.handleSubmit}>
                                        <FormGroup
                                            className={classnames('mb-3', {
                                                // focused: focusedEmail,
                                            })}
                                        >
                                            <InputGroup className="input-group-merge input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-email-83" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Email"
                                                    type="email"
                                                    onChange={(e) => this.setState({ email: e.target.value })}
                                                    // onFocus={() => setfocusedEmail(true)}
                                                    // onBlur={() => setfocusedEmail(true)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup
                                            className={classnames({
                                                // focused: focusedPassword,
                                            })}
                                        >
                                            <InputGroup className="input-group-merge input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-lock-circle-open" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Senha"
                                                    type="password"
                                                    onChange={(e) => this.setState({ password: e.target.value })}
                                                    // onFocus={() => setfocusedPassword(true)}
                                                    // onBlur={() => setfocusedPassword(true)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        {/* <div className="custom-control custom-control-alternative custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id=" customCheckLogin"
                                            type="checkbox"
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor=" customCheckLogin"
                                        >
                                            <span className="text-muted">Lembre de mim</span>
                                        </label>
                                    </div> */}
                                        <div className="text-center">
                                            <Button className="my-4" color="info" type="submit">
                                                Entrar
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                            <Row className="mt-3">
                                <Col xs="6">
                                    <a className="text-light" href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <small>Esqueceu a senha?</small>
                                    </a>
                                </Col>
                                <Col className="text-right" xs="6">
                                    <a className="text-light" href="https://tradertax.com.br/criar-conta" onClick={(e) => e.preventDefault()}>
                                        <small>Crie uma nova conta</small>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default withRouter(Login);
