import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Table,
  Badge,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { Component } from "react";
import api from "services/api";

class Apurations extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      apurations: [],
    }
  }

  async componentDidMount() {
    const response = await api
      .get("/apurations")
      .then(function (response) {
          return response.data;
      })
      .catch(function (error) {
          console.log("Houve um problema ao buscar as apurações, tente novamente em alguns instantes.");
      });
    this.setState({apurations: response});
  }

  render() {
    return (
    <>
      <SimpleHeader name="Apurações Mensais" parentName="Painel" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Acesse seus relatórios mensais</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg="8">
                      <p className="mb-0">
                        Aqui você encontra seus relatórios mensais entregues via
                        e-mail e também disponíveis aqui.
                      </p>
                    </Col>
                  </Row>

                  <Table
                    className="align-items-center table-flush mt-3"
                    responsive
                  >
                    <thead>
                      <tr>
                        <th className="sort" data-sort="name" scope="col">
                          Estado
                        </th>
                        <th className="sort" data-sort="budget" scope="col">
                          Plano
                        </th>
                        <th className="sort" data-sort="status" scope="col">
                          Mês Referência
                        </th>
                        <th className="sort" data-sort="completion" scope="col">
                          Notas Enviadas?
                        </th>
                        <th className="sort" data-sort="completion" scope="col">
                          Relatório disponível?
                        </th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody className="list">
                      {this.state.apurations?.map((apuration, index) => (
                        <tr key={apuration.id}>
                          <th scope="row">
                            <Badge color="success">{apuration.statusPublicText}</Badge>
                          </th>
                          <td className="budget">{apuration.plan}</td>
                          <td>{apuration.month}</td>
                          <td>
                            <Badge color={apuration.isBrokerageNotesSent ? 'primary':'danger'}>{apuration.isBrokerageNotesSent ? 'Sim':'Não'}</Badge>
                          </td>
                          <td>
                            <Badge color={apuration.isReportAvailable ? 'primary':'danger'}>{apuration.isReportAvailable ? 'Sim':'Não'}</Badge>
                          </td>
                          <td>
                            <Button
                              className="btn-neutral btn-round btn-icon"
                              color="primary"
                              href={`/admin/apurations/${apuration.id}`}
                              id={`tooltip${apuration.id}`}
                              size="sm"
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-eye" />
                              </span>
                              <span className="btn-inner--text">
                                Ver detalhes
                              </span>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target={`tooltip${apuration.id}`}
                            >
                              Clique para ver o relatório
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
    );
  }
}

export default Apurations;
