/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

function AuthFooter() {
  return (
    <>
      <footer className="py-5" id="footer-main">
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="6">
              <div className="copyright text-center text-xl-left text-muted">
                © {new Date().getFullYear()}{" "}
                LEAN SERVICOS DE CONTABILIDADE LTDA — CNPJ 34.479.161/0001-21
              </div>
            </Col>
            <Col xl="6">
              <Nav className="nav-footer justify-content-center justify-content-xl-end">
                <NavItem>
                  <NavLink href="https://www.tradertax.com.br/" target="_blank">
                    TRADERTAX
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="https://www.tradertax.com.br/termos-de-uso"
                    target="_blank"
                  >
                    Termos de uso
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="https://tradertax.com.br/termo-de-consentimento"
                    target="_blank"
                  >
                    Termo de consentimento
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default AuthFooter;
