import SimpleHeader from 'components/Headers/SimpleHeader.js';
import Select from 'components/Select';
import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Row, Table } from 'reactstrap';
import api from 'services/api';
import * as moment from 'moment';
import * as currency from 'currency.js';

const monthsOptions = [];
for (let month = moment().add(4, 'months'); month.utc(true).isSameOrAfter('2018-01-01', 'month'); month = month.subtract(1, 'months')) {
    monthsOptions.push({ value: month.format('YYYY-MM'), label: String(month.format('YYYY-MM')).split('-').reverse().join('/') });
}
class Wallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wallet: [],
            filterYearMonth: moment().format('YYYY-MM'),
        };
    }

    async componentDidMount() {
        this.handleLoadWallet();
    }

    handleFormFilterSubmit = () => {
        this.handleLoadWallet();
    };

    handleFormFilterReset = (e) => {
        e.preventDefault();
        this.form.reset();
        this.setState(
            {
                filterYearMonth: undefined,
                filterYear: undefined,
            },
            this.handleLoadWallet,
        );
    };

    handleLoadWallet = async () => {
        try {
            const filterYearMonth = this.state.filterYearMonth;
            const wallet = await api
                .get('/operations/wallet', {
                    params: {
                        year: filterYearMonth?.split('-')[0],
                        month: filterYearMonth?.split('-')[1],
                    },
                })
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    throw new Error('Houve um problema ao obter a carteira.');
                });
            if (!wallet) {
                throw new Error('Carteira vazia.');
            }
            this.setState({ wallet });
        } catch (err) {
            console.log(err);
        }
    };

    handleFilterYearMonthOnChange = async (e) => {
        await this.setState({ filterYearMonth: e?.value }, this.handleFormFilterSubmit());
    };

    render() {
        return (
            <>
            <SimpleHeader name="Carteira" parentName="Painel" />
            <Container className="mt--6" fluid>
                <Card>
                    <CardBody>
                        <Row>
                            <Col xl="2">
                                <label className="form-control-label" htmlFor="selectFilterYearMonth">
                                    Ano/Mês
                                </label>
                                <Select
                                    id="selectFilterYearMonth"
                                    isClearable={false}
                                    value={monthsOptions.filter((option) => option.value === this.state.filterYearMonth)}
                                    options={monthsOptions}
                                    onChange={(e) =>
                                        this.setState(
                                            {
                                                filterYearMonth: e?.value,
                                            },
                                            this.handleLoadWallet,
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <h3 className="mb-0">Ativos em Custódia</h3>
                        <Table className="align-items-center table-flush mt-4" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Ativo</th>
                                    <th scope="col">CNPJ</th>
                                    <th scope="col">Quantidade</th>
                                    <th scope="col">Preço Médio</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Descrição</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.wallet?.filter((w) => w.quantity > 0).map((wallet, index) => (
                                    <tr key={index}>
                                        <th scope="row">{wallet.asset}</th>
                                        <td>{wallet.assetDocNumber ?? '-'}</td>
                                        <td>{wallet.quantity}</td>
                                        <td>{currency(Number(wallet.averagePriceBuy), { separator: '.', decimal: ',', symbol: 'R$ ' }).format()}</td>
                                        <td>{currency(Number(wallet.totalValue), { separator: '.', decimal: ',', symbol: 'R$ ' }).format()}</td>
                                        <td>{wallet.assetName ?? '-'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <h3 className="mb-0">Posição Vendida</h3>
                        <Table className="align-items-center table-flush mt-4" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Ativo</th>
                                    <th scope="col">CNPJ</th>
                                    <th scope="col">Quantidade</th>
                                    <th scope="col">Preço Médio</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Descrição</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.wallet?.filter((w) => w.quantity < 0).map((wallet, index) => (
                                    <tr key={index}>
                                        <th scope="row">{wallet.asset}</th>
                                        <td>{wallet.assetDocNumber ?? '-'}</td>
                                        <td>{wallet.quantity}</td>
                                        <td>{currency(Number(wallet.averagePriceBuy), { separator: '.', decimal: ',', symbol: 'R$ ' }).format()}</td>
                                        <td>{currency(Number(wallet.totalValue), { separator: '.', decimal: ',', symbol: 'R$ ' }).format()}</td>
                                        <td>{wallet.assetName ?? '-'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Container>
            </>
        );
    }
}

export default Wallet;
