import React, { Component } from 'react';
// import ReactDatetime from "react-datetime";
import { Input, Col, Button } from 'reactstrap';
import Select from 'components/Select';

const cvOptions = [
    { value: 'c', label: 'Compra' },
    { value: 'v', label: 'Venda' },
];

const marketTypeOptions = [
    { value: 'common', label: 'Vista / Leilão' },
    { value: 'common_term', label: 'Termo' },
    { value: 'common_option_buy', label: 'Opç. Compra' },
    { value: 'common_option_sell', label: 'Opç. Venda' },
    { value: 'common_option_due', label: 'Exer. Opção' },
    { value: 'common_fii', label: 'Fundos imobiliários' },
    { value: 'future', label: 'Futuro & BM&F' },
];

class Operation extends Component {
    // componentWillReceiveProps = (nextProps) => {
    //   if (nextProps.value && nextProps.operations && nextProps.totalCosts && nextProps.onChange) {
    //     nextProps.onChange({cost: this.getCost()}, 'cost');
    //   }
    // }

    getCost = () => {
        const quantity = this.props.value.quantity ? parseInt(this.props.value.quantity) : 0;
        const price = this.props.value.price ? parseFloat(this.props.value.price) : 0;
        const amount = quantity * price;
        const operationsTotalAmount = this.props.operations?.reduce((sum, operation) => {
            const quantity = operation.quantity ? parseInt(operation.quantity) : 0;
            const price = operation.price ? parseFloat(operation.price) : 0;
            return sum + quantity * price;
        }, 0);
        const percentage = amount > 0 ? amount / operationsTotalAmount : 0;
        const cost = (this.props.totalCosts * percentage).toFixed(2);
        // this.props.onChange({cost}, 'cost');
        return cost;
    };

    render() {
        const operation = this.props.value;
        const assets = this.props.assets;
        const assetOptions = assets.map((asset) => {
            return { value: asset.id, label: asset.ticker };
        });
        return (
            <>
                <div className="form-row">
                    <Col className="mb-3" md="1" lg="1">
                        <Select placeholder="C/V" value={cvOptions.find((option) => option.value === operation.cv)} id="validationCustomCV" options={cvOptions} required onChange={(e) => this.props.onChange({ cv: e.value }, operation.key)} />
                    </Col>
                    <Col className="mb-3" md="2" lg="2">
                        <Select placeholder="Tipo Mercado" value={marketTypeOptions.find((option) => option.value === operation.marketType)} id="validationCustomMarketType" options={marketTypeOptions} required onChange={(e) => this.props.onChange({ marketType: e.value }, operation.key)} />
                    </Col>
                    <Col className="mb-3" md="2" lg="2">
                        <Select placeholder="Ativo" value={assetOptions.find((option) => option.value === operation.asset.id)} id="validationCustomAsset" options={assetOptions} required onChange={(e) => this.props.onChange({ asset: assets.find((asset) => asset.id === e.value) }, operation.key)} />
                    </Col>
                    <Col className="mb-3" md="2" lg="1">
                        <Input placeholder="Quantidade" value={operation.quantity} id="validationCustomQuantity" required type="number" onChange={(e) => this.props.onChange({ quantity: e.target.value }, operation.key)} />
                    </Col>
                    <Col className="mb-3" md="2" lg="1">
                        <Input placeholder="Preço" value={operation.price} id="validationCustomPrice" required type="number" step="any" onChange={(e) => this.props.onChange({ price: e.target.value }, operation.key)} />
                    </Col>
                    <Col className="mb-3" md="2" lg="1">
                        <Input placeholder="Custos da Ordem" value={this.getCost()} id="validationCustomCost" readOnly type="number" />
                    </Col>
                    <Col className="mb-3" md="2" lg="3">
                        <Input placeholder="Observação" id="validationCustomObservation" value={operation.observation ?? ''} type="text" onChange={(e) => this.props.onChange({ observation: e.target.value }, operation.key)} />
                    </Col>
                    <Col className="mb-3" md="1" lg="1">
                        <Button color="danger" type="button" outline={true} onClick={(e) => this.props.onDelete(operation.key)}>
                            Remover
                        </Button>
                    </Col>
                </div>
            </>
        );
    }
}

export default Operation;
