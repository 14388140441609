import SimpleHeader from 'components/Headers/SimpleHeader.js';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import * as moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

class Reports extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <SimpleHeader name="Relatórios" parentName="Painel"></SimpleHeader>
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <div className="card-wrapper">
                                <Card>
                                    <CardHeader>
                                        <h3 className="mb-0">Relatórios disponíveis</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col lg="8">{/* <p className="mb-0">Todas as operações de sua carteira em ordem cronológica. Use os controles abaixo para filtrar o resultado.</p> */}</Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Reports;
