import React, { Component } from 'react';
import { Button, Card, CardHeader, CardBody, Form, Input, Container, Row, Col } from 'reactstrap';
import Select from 'components/Select';
import Operation from 'components/Operation';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import * as moment from 'moment';
import api from 'services/api';

class BrokerageNotesForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialCustody: null,
            brokers: [],
            assets: [],
            operations: [],
            brokerageNoteId: null,
            noteNumber: '',
            brokerId: '',
            date: '',
            taxaLiquidacao: '',
            taxaRegistro: '',
            taxaTermos: '',
            taxaAna: '',
            emolumentos: '',
            taxaOperacional: '',
            execucao: '',
            taxaCustodia: '',
            iss: '',
            outros: '',
            irrf: '',
            totalCost: '',
            error: '',
        };
    }

    componentDidMount() {
        this.loadInitialCustody();
        if (this.props.match.params.id) {
            this.findBrokerageNoteById(this.props.match.params.id);
        }
        this.loadBrokers();
        this.loadAssets();
    }

    loadInitialCustody = async (id) => {
        try {
            const result = await api
                .get(`/initial-custody`)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    throw new Error('Houve um problema ao obter os dados da custódia inicial.');
                });
            if (!result) return false;
            this.setState({ initialCustody: result });
        } catch (err) {
            console.log(err);
        }
    };

    loadBrokers = async () => {
        try {
            const noteNumber = this.state.noteNumber;
            const brokers = await api
                .get('/brokers', {
                    params: {
                        noteNumber,
                    },
                })
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    throw new Error('Houve um problema ao obter as corretoras.');
                });
            this.setState({ brokers });
        } catch (err) {
            console.log(err);
        }
    };

    loadAssets = async () => {
        try {
            const assets = await api
                .get('/assets')
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    throw new Error('Houve um problema ao obter as corretoras.');
                });
            this.setState({ assets });
        } catch (err) {
            console.log(err);
        }
    };

    findBrokerageNoteById = async (id) => {
        try {
            if (!id) return false;
            const result = await api
                .get(`/brokerage-notes/${id}`)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    throw new Error('Houve um problema ao obter a nota de corretagem.');
                });
            if (!result) return false;
            const brokerageNote = result;
            await this.setState({
                brokerageNoteId: brokerageNote.id,
                noteNumber: brokerageNote.code,
                brokerId: brokerageNote.brokerId,
                date: moment(brokerageNote.date).format('YYYY-MM-DD'),
                taxaLiquidacao: brokerageNote.taxaLiquidacao ?? '',
                taxaRegistro: brokerageNote.taxaRegistro ?? '',
                taxaTermos: brokerageNote.taxaTermos ?? '',
                taxaAna: brokerageNote.taxaAna ?? '',
                emolumentos: brokerageNote.emolumentos ?? '',
                taxaOperacional: brokerageNote.taxaOperacional ?? '',
                execucao: brokerageNote.execucao ?? '',
                taxaCustodia: brokerageNote.taxaCustodia ?? '',
                iss: brokerageNote.iss ?? '',
                outros: brokerageNote.outros ?? '',
                irrf: brokerageNote.irrf ?? '',
            });
            await this.loadOperations(brokerageNote.id);
        } catch (err) {
            console.log(err);
        }
    };

    findBrokerageNote = async (code, brokerId, date) => {
        try {
            if (!code || !brokerId || !date) {
                return false;
            }
            const result = await api
                .get(`/brokerage-notes`, {
                    params: {
                        code,
                        brokerId,
                        date,
                    },
                })
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    throw new Error('Houve um problema ao obter a nota de corretagem.');
                });
            if (!result) return false;
            const brokerageNote = result.length ? result[0] : null;
            if (!brokerageNote) return false;
            await this.loadOperations(brokerageNote.id);
            await this.setState({
                brokerageNoteId: brokerageNote.id,
                taxaLiquidacao: brokerageNote.taxaLiquidacao ?? '',
                taxaRegistro: brokerageNote.taxaRegistro ?? '',
                taxaTermos: brokerageNote.taxaTermos ?? '',
                taxaAna: brokerageNote.taxaAna ?? '',
                emolumentos: brokerageNote.emolumentos ?? '',
                taxaOperacional: brokerageNote.taxaOperacional ?? '',
                execucao: brokerageNote.execucao ?? '',
                taxaCustodia: brokerageNote.taxaCustodia ?? '',
                iss: brokerageNote.iss ?? '',
                outros: brokerageNote.outros ?? '',
                irrf: brokerageNote.irrf ?? '',
            });
        } catch (err) {
            console.log(err);
        }
    };

    loadOperations = async (brokerageNoteId) => {
        try {
            const operations = await api
                .get(`/operations`, { params: { brokerageNoteId } })
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    throw new Error('Houve um problema ao obter as operações.');
                });
            operations.map((operation) => (operation.key = operation.id ?? Math.random().toString()));
            this.setState({ operations });
        } catch (err) {
            console.log(err);
        }
    };

    handleChangeNoteNumber = async (e) => {
        await this.setState({ noteNumber: e?.target.value ?? null });
        this.findBrokerageNote(this.state.noteNumber, this.state.brokerId, this.state.date);
    };

    handleChangeBroker = async (e) => {
        await this.setState({ brokerId: e?.value ?? null });
        this.findBrokerageNote(this.state.noteNumber, this.state.brokerId, this.state.date);
    };

    handleChangeDate = async (e) => {
        await this.setState({ date: e?.target.value.split('/').reverse().join('-') ?? null });
        this.findBrokerageNote(this.state.noteNumber, this.state.brokerId, this.state.date);
    };

    handleClickAddOperation = (e) => {
        this.setState((state) => {
            return {
                operations: state.operations.concat([
                    {
                        key: Math.random().toString(),
                        cv: '',
                        marketType: '',
                        asset: '',
                        quantity: '',
                        price: '',
                        cost: '',
                        observation: '',
                    },
                ]),
            };
        });
    };

    handleOnChangeOperation = (values, key) => {
        const operations = this.state.operations;
        operations.map(function (operation) {
            if (operation.key === key) {
                for (const prop in values) {
                    operation[prop] = values[prop];
                }
            }
            return operation;
        });
        this.setState({ operations: operations });
    };

    handleOnDeleteOperation = (key) => {
        this.setState((state) => {
            return {
                operations: state.operations.filter((operation) => operation.key !== key),
            };
        });
    };

    getBrokers = () => {
        return this.state.brokers.slice().map((broker) => {
            return {
                value: broker.id,
                label: `${broker.name} (${broker.code})`,
            };
        });
    };

    getTotalCosts = () => {
        return parseFloat(
            (this.state.taxaLiquidacao ? parseFloat(this.state.taxaLiquidacao) : 0) +
                (this.state.taxaRegistro ? parseFloat(this.state.taxaRegistro) : 0) +
                (this.state.taxaTermos ? parseFloat(this.state.taxaTermos) : 0) +
                (this.state.taxaAna ? parseFloat(this.state.taxaAna) : 0) +
                (this.state.emolumentos ? parseFloat(this.state.emolumentos) : 0) +
                (this.state.taxaOperacional ? parseFloat(this.state.taxaOperacional) : 0) +
                (this.state.execucao ? parseFloat(this.state.execucao) : 0) +
                (this.state.taxaCustodia ? parseFloat(this.state.taxaCustodia) : 0) +
                (this.state.iss ? parseFloat(this.state.iss) : 0) +
                (this.state.outros ? parseFloat(this.state.outros) : 0),
        ).toPrecision(3);
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const { brokerageNoteId, noteNumber, date, brokerId, operations, taxaLiquidacao, taxaRegistro, taxaTermos, taxaAna, emolumentos, taxaOperacional, execucao, taxaCustodia, iss, outros, irrf } = this.state;
        try {
            if (!noteNumber) {
                throw new Error('Informe o Número da Nota para continuar!');
            }
            if (!brokerId) {
                throw new Error('Informe o Corretora para continuar!');
            }
            if (!date) {
                throw new Error('Informe a Data do Pregão para continuar!');
            }
            if (!brokerageNoteId && (!operations || operations.length === 0)) {
                throw new Error('Informe as Operações para continuar!');
            }
            let response = null;
            if (brokerageNoteId) {
                response = await api
                    .put(`/brokerage-notes/${brokerageNoteId}`, {
                        operations,
                        taxaLiquidacao,
                        taxaRegistro,
                        taxaTermos,
                        taxaAna,
                        emolumentos,
                        taxaOperacional,
                        execucao,
                        taxaCustodia,
                        iss,
                        outros,
                        irrf,
                    })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (error) {
                        throw new Error('Houve um problema ao atualizar as operações.');
                    });
            } else {
                response = await api
                    .post('/brokerage-notes', {
                        code: noteNumber,
                        brokerId,
                        date,
                        operations,
                        taxaLiquidacao,
                        taxaRegistro,
                        taxaTermos,
                        taxaAna,
                        emolumentos,
                        taxaOperacional,
                        execucao,
                        taxaCustodia,
                        iss,
                        outros,
                        irrf,
                    })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (error) {
                        throw new Error('Houve um problema ao cadastrar as operações.');
                    });
            }
            if (!response || !response.id) {
                throw new Error('Houve um problema ao cadastrar as operações');
            }
            this.props.history.push('/admin/operations');
        } catch (err) {
            this.setState({ error: err.message });
        }
    };

    render() {
        return (
            <>
                <SimpleHeader name="Operações" parentName="Painel" />
                <Container className="mt--6" fluid>
                    <Form role="form" onSubmit={this.handleSubmit}>
                        <Row>
                            <div className="col">
                                <div className="card-wrapper">
                                    <Card>
                                        <CardHeader>
                                            <h2 className="mb-0">Nota de Corretagem</h2>
                                            {this.state.error && <p style={{ color: 'red' }}>{this.state.error}</p>}
                                        </CardHeader>
                                        <CardBody>
                                            <div className="form-row">
                                                <Col className="mb-3" md="4" lg="4">
                                                    <label className="form-control-label" htmlFor="validationCustomNoteNumber">
                                                        Número da Nota *
                                                    </label>
                                                    <Input value={this.state.noteNumber} id="validationCustomNoteNumber" placeholder="" required type="number" onChange={(e) => this.handleChangeNoteNumber(e)} readOnly={!!this.props.match.params.id} />
                                                </Col>
                                                <Col className="mb-3" md="4" lg="4">
                                                    <label className="form-control-label" htmlFor="validationBroker">
                                                        Corretora *
                                                    </label>
                                                    <Select
                                                        id="validationBroker"
                                                        isClearable={true}
                                                        value={this.getBrokers().filter((option) => option.value === this.state.brokerId)}
                                                        options={this.getBrokers()}
                                                        onChange={(e) => this.handleChangeBroker(e)}
                                                        isDisabled={!!this.props.match.params.id}
                                                    />
                                                </Col>
                                                <Col className="mb-3" md="4" lg="4">
                                                    <label className="form-control-label" htmlFor="validationCustomDate">
                                                        Data do Pregão *
                                                    </label>
                                                    <Input value={this.state.date} id="validationCustomDate" placeholder="" required type="date" min={this.state.initialCustody?.initialDateOperations} onChange={(e) => this.handleChangeDate(e)} readOnly={!!this.props.match.params.id} />
                                                </Col>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className="col">
                                <div className="card-wrapper">
                                    <Card>
                                        <CardHeader>
                                            <h2 className="mb-0">Operações</h2>
                                        </CardHeader>
                                        <CardBody>
                                            {/* <Row>
                      <Col lg="8">
                        <p className="mb-0">
                          Nesta seção, você deve informar seus prejuízos
                          acumulados. Caso você já possua uma assessoria
                          tributária da B3, informe aqui seus prejuízos
                          acumulados. O valor que você informar aqui afetará toda
                          e qualquer apuração gerada pelo sistema da data inicial
                          para frente.
                        </p>
                      </Col>
                    </Row>
                    <hr /> */}

                                            <div className="form-row">
                                                <Col className="mb-3" md="1" lg="1">
                                                    <label className="form-control-label">C/V</label>
                                                </Col>
                                                <Col className="mb-3" md="2" lg="2">
                                                    <label className="form-control-label">Tipo Mercado</label>
                                                </Col>
                                                <Col className="mb-3" md="2" lg="2">
                                                    <label className="form-control-label">Ativo</label>
                                                </Col>
                                                <Col className="mb-3" md="2" lg="1">
                                                    <label className="form-control-label">Quantidade</label>
                                                </Col>
                                                <Col className="mb-3" md="2" lg="1">
                                                    <label className="form-control-label">Preço</label>
                                                </Col>
                                                <Col className="mb-3" md="2" lg="1">
                                                    <label className="form-control-label">Custos da Ordem</label>
                                                </Col>
                                                <Col className="mb-3" md="2" lg="3">
                                                    <label className="form-control-label">Observação</label>
                                                </Col>
                                                <Col className="mb-3" md="1" lg="1"></Col>
                                            </div>
                                            {this.state.operations.map((operation, index) => (
                                                <div key={index}>
                                                    <Operation key={index} value={operation} onChange={this.handleOnChangeOperation} onDelete={this.handleOnDeleteOperation} totalCosts={this.getTotalCosts()} operations={this.state.operations} assets={this.state.assets} />
                                                    <hr className="mt-0 mb-3" />
                                                </div>
                                            ))}
                                            <Button color="primary" type="submit" onClick={(e) => this.handleClickAddOperation(e)} disabled={!this.state.noteNumber || !this.state.brokerId || !this.state.date}>
                                                Adicionar Operação
                                            </Button>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className="col">
                                <div className="card-wrapper">
                                    <Card>
                                        <CardHeader>
                                            <h2 className="mb-0">Custos da nota</h2>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="form-row">
                                                <Col className="mb-3" md="2" lg="2">
                                                    <label className="form-control-label" htmlFor="validationCustomNoteNumber">
                                                        Taxa de Liquidação
                                                    </label>
                                                    <Input value={this.state.taxaLiquidacao} id="validationCustomNoteNumber" placeholder="" type="number" step="any" onChange={(e) => this.setState({ taxaLiquidacao: e.target.value })} />
                                                </Col>
                                                <Col className="mb-3" md="2" lg="2">
                                                    <label className="form-control-label" htmlFor="validationCustomNoteNumber">
                                                        Taxa de Registro
                                                    </label>
                                                    <Input value={this.state.taxaRegistro} id="validationCustomNoteNumber" placeholder="" type="number" step="any" onChange={(e) => this.setState({ taxaRegistro: e.target.value })} />
                                                </Col>
                                                <Col className="mb-3" md="2" lg="2">
                                                    <label className="form-control-label" htmlFor="validationCustomNoteNumber">
                                                        Taxa de Termos/Opções
                                                    </label>
                                                    <Input value={this.state.taxaTermos} id="validationCustomNoteNumber" placeholder="" type="number" step="any" onChange={(e) => this.setState({ taxaTermos: e.target.value })} />
                                                </Col>
                                                <Col className="mb-3" md="2" lg="2">
                                                    <label className="form-control-label" htmlFor="validationCustomNoteNumber">
                                                        Taxa A.N.A.
                                                    </label>
                                                    <Input value={this.state.taxaAna} id="validationCustomNoteNumber" placeholder="" type="number" step="any" onChange={(e) => this.setState({ taxaAna: e.target.value })} />
                                                </Col>
                                                <Col className="mb-3" md="2" lg="2">
                                                    <label className="form-control-label" htmlFor="validationCustomNoteNumber">
                                                        emolumentos
                                                    </label>
                                                    <Input value={this.state.emolumentos} id="validationCustomNoteNumber" placeholder="" type="number" step="any" onChange={(e) => this.setState({ emolumentos: e.target.value })} />
                                                </Col>
                                                <Col className="mb-3" md="2" lg="2">
                                                    <label className="form-control-label" htmlFor="validationCustomNoteNumber">
                                                        Taxa Operacional
                                                    </label>
                                                    <Input value={this.state.taxaOperacional} id="validationCustomNoteNumber" placeholder="" type="number" step="any" onChange={(e) => this.setState({ taxaOperacional: e.target.value })} />
                                                </Col>
                                                <Col className="mb-3" md="2" lg="2">
                                                    <label className="form-control-label" htmlFor="validationCustomNoteNumber">
                                                        Execução
                                                    </label>
                                                    <Input value={this.state.execucao} id="validationCustomNoteNumber" placeholder="" type="number" step="any" onChange={(e) => this.setState({ execucao: e.target.value })} />
                                                </Col>
                                                <Col className="mb-3" md="2" lg="2">
                                                    <label className="form-control-label" htmlFor="validationCustomNoteNumber">
                                                        Taxa de Custódia
                                                    </label>
                                                    <Input value={this.state.taxaCustodia} id="validationCustomNoteNumber" placeholder="" type="number" step="any" onChange={(e) => this.setState({ taxaCustodia: e.target.value })} />
                                                </Col>
                                                <Col className="mb-3" md="2" lg="2">
                                                    <label className="form-control-label" htmlFor="validationCustomNoteNumber">
                                                        ISS
                                                    </label>
                                                    <Input value={this.state.iss} id="validationCustomNoteNumber" placeholder="" type="number" step="any" onChange={(e) => this.setState({ iss: e.target.value })} />
                                                </Col>
                                                <Col className="mb-3" md="2" lg="2">
                                                    <label className="form-control-label" htmlFor="validationCustomNoteNumber">
                                                        Outros
                                                    </label>
                                                    <Input value={this.state.outros} id="validationCustomNoteNumber" placeholder="" type="number" step="any" onChange={(e) => this.setState({ outros: e.target.value })} />
                                                </Col>
                                                <Col className="mb-3" md="2" lg="2">
                                                    <label className="form-control-label" htmlFor="validationCustomNoteNumber">
                                                        IRRF
                                                    </label>
                                                    <Input value={this.state.irrf} id="validationCustomNoteNumber" placeholder="" type="number" step="any" onChange={(e) => this.setState({ irrf: e.target.value })} />
                                                </Col>
                                                <Col className="mb-3" md="2" lg="2">
                                                    <label className="form-control-label" htmlFor="validationCustomNoteNumber">
                                                        Total dos Custos
                                                    </label>
                                                    <Input value={this.getTotalCosts()} id="validationCustomTotalCosts" placeholder="" disabled type="number" step="any" />
                                                </Col>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </Row>
                        <Row className="mb-6">
                            <Col>
                                <Button color="primary" type="submit">
                                    Enviar Cadastro
                                </Button>
                                <Button color="white" type="button" href={`/admin/operations`}>
                                    Cancelar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </>
        );
    }
}

export default BrokerageNotesForm;
