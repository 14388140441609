import api from 'services/api';
// import Chart from 'chart.js/auto';
// import classnames from 'classnames';
import 'chart.js/auto';
import { Chart, Pie } from 'react-chartjs-2';
import * as moment from 'moment';
import React, { Component } from 'react';
import * as currency from 'currency.js';
import { withRouter } from 'react-router-dom';
// import { Bar, Pie, Utils } from 'react-chartjs-2';

import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row, Table } from 'reactstrap';
// import { parseOptions, chartOptions } from 'variables/charts.js';

const colors = {
    default: '#172b4d',
    primary: '#5e72e4',
    secondary: '#f4f5f7',
    info: '#11cdef',
    success: '#2dce89',
    danger: '#f5365c',
    warning: '#fb6340',
};
class Dashboard extends Component {
    chartLabel = [];
    chartValues = [];

    constructor(props) {
        super(props);
        this.state = {
            activeNav: 1,
            chartExample1Data: 'data1',
            wallet: [],
            chatWalletLabels: [],
            chatWalletData: [],
            patrimonyCurrentMonth: '',
            patrimonyLastMonth: '',
            assetsCountCurrentMonth: '',
            assetsCountLastMonth: '',
            accumulatedLossesSwingtradeCurrentMonth: '',
            accumulatedLossesSwingtradeLastMonth: '',
            accumulatedLossesDaytradeCurrentMonth: '',
            accumulatedLossesDaytradeLastMonth: '',
            accumulatedLossesFiisCurrentMonth: '',
            accumulatedLossesFiisLastMonth: '',
            performanceCurrentMonth: '',
            performanceLastMonth: '',
            resultsByMonth: [],
            resultsByMonthLabel: [],
            resultsByMonthValues: [],
            resultsByMonthValuesAccumulated: [],
        };

        // if (window.Chart) {
        //     parseOptions(Chart, chartOptions());
        // }
    }

    async componentDidMount() {
        this.handleLoadMonthInfo();
        this.handleLoadWallet();
        this.handleLoadResultsByMonth();
    }

    handleLoadMonthInfo = async () => {
        try {
            const filterMonth = moment().format('MM');
            const filterYear = moment().format('YYYY');
            const monthInfo = await api
                .get('/operations/month-info', {
                    params: {
                        month: filterMonth,
                        year: filterYear,
                    },
                })
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    throw new Error('Houve um problema ao obter as informações.');
                });
            this.setState({
                patrimonyCurrentMonth: monthInfo.patrimonyCurrentMonth,
                patrimonyLastMonth: monthInfo.patrimonyLastMonth,
                assetsCountCurrentMonth: monthInfo.assetsCountCurrentMonth,
                assetsCountLastMonth: monthInfo.assetsCountLastMonth,
                accumulatedLossesSwingtradeCurrentMonth: monthInfo.accumulatedLossesSwingtradeCurrentMonth,
                accumulatedLossesSwingtradeLastMonth: monthInfo.accumulatedLossesSwingtradeLastMonth,
                accumulatedLossesDaytradeCurrentMonth: monthInfo.accumulatedLossesDaytradeCurrentMonth,
                accumulatedLossesDaytradeLastMonth: monthInfo.accumulatedLossesDaytradeLastMonth,
                accumulatedLossesFiisCurrentMonth: monthInfo.accumulatedLossesFiisCurrentMonth,
                accumulatedLossesFiisLastMonth: monthInfo.accumulatedLossesFiisLastMonth,
                performanceCurrentMonth: monthInfo.performanceCurrentMonth,
                performanceLastMonth: monthInfo.performanceLastMonth,
            });
        } catch (err) {
            console.log(err);
        }
    };

    handleLoadResultsByMonth = async () => {
        try {
            const year = moment().format('YYYY');
            const resultsByMonth = await api
                .get('/operations/results-by-month', { params: { year } })
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    throw new Error('Houve um problema ao obter as informações.');
                });
            this.setState({
                resultsByMonth,
                resultsByMonthLabel: resultsByMonth.months.map((result) => result.label),
                resultsByMonthValues: resultsByMonth.months.map((result) => result.amount),
                resultsByMonthValuesAccumulated: resultsByMonth.months.map((result) => result.accumulated),
            });
            return resultsByMonth;
        } catch (err) {
            console.log(err);
        }
    };

    handleLoadWallet = async () => {
        try {
            const filterMonth = moment().format('MM');
            const filterYear = moment().format('YYYY');
            const wallet = await api
                .get('/operations/wallet', {
                    params: {
                        month: filterMonth,
                        year: filterYear,
                    },
                })
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    throw new Error('Houve um problema ao obter a carteira.');
                });
            if (!wallet) {
                throw new Error('Carteira vazia.');
            }
            const chatLabels = [];
            const chatData = [];
            const sumQuantity = wallet.reduce((sum, asset) => {
                return (sum += asset.quantity > 0 ? asset.quantity : 0);
            }, 0);
            for (const asset of wallet) {
                if (asset.quantity > 0) {
                    chatLabels.push(asset.asset);
                    chatData.push((asset.quantity / sumQuantity) * 100);
                }
            }
            this.setState({
                wallet,
                chatWalletLabels: chatLabels,
                chatWalletData: chatData,
            });
        } catch (err) {
            console.log(err);
        }
    };

    render() {
        return (
            <>
                <div className="header bg-info pb-6">
                    <Container fluid>
                        <div className="header-body">
                            <Row className="align-items-center py-4">
                                <Col lg="6" xs="7">
                                    <h6 className="h2 text-white d-inline-block mb-0">Início</h6>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="6" xl="3">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                                                        Seu patrimônio
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                                                        {currency(Number(this.state.patrimonyCurrentMonth), {
                                                            separator: '.',
                                                            decimal: ',',
                                                            symbol: 'R$ ',
                                                        }).format()}
                                                    </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                                        <i className="ni ni-active-40" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                                                <span className={`text-${this.state.patrimonyCurrentMonth - this.state.patrimonyLastMonth >= 0 ? 'success' : 'danger'} mr-2`}>
                                                    <i className={`fa fa-arrow-${this.state.patrimonyCurrentMonth - this.state.patrimonyLastMonth > 0 ? 'up' : 'down'}`} />{' '}
                                                    {this.state.patrimonyCurrentMonth
                                                        ? currency(((this.state.patrimonyLastMonth - this.state.patrimonyCurrentMonth) / this.state.patrimonyCurrentMonth) * 100, {
                                                              separator: '',
                                                              decimal: ',',
                                                              symbol: '',
                                                          }).format()
                                                        : '-'}
                                                    %
                                                </span>
                                                &nbsp;<span className="text-nowrap">desde mês passado</span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6" xl="3">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                                                        Ativos
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">{this.state.assetsCountCurrentMonth}</span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                                        <i className="ni ni-chart-pie-35" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                                                &nbsp;
                                                <span className={`text-${this.state.assetsCountCurrentMonth - this.state.assetsCountLastMonth >= 0 ? 'success' : 'danger'} mr-2`}>
                                                    <i className={`fa fa-arrow-${this.state.assetsCountCurrentMonth - this.state.assetsCountLastMonth >= 0 ? 'up' : 'down'}`} />
                                                    &nbsp;
                                                    {this.state.assetsCountCurrentMonth
                                                        ? currency(((this.state.assetsCountLastMonth - this.state.assetsCountCurrentMonth) / this.state.assetsCountCurrentMonth) * 100, {
                                                              separator: '',
                                                              decimal: ',',
                                                              symbol: '',
                                                              precision: 0,
                                                          }).format()
                                                        : '-'}
                                                </span>
                                                &nbsp;
                                                <span className="text-nowrap">desde mês passado</span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6" xl="3">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                                                        Prejuízos Swing-Trade
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                                                        {currency(Number(this.state.accumulatedLossesSwingtradeCurrentMonth), {
                                                            separator: '.',
                                                            decimal: ',',
                                                            symbol: 'R$ ',
                                                        }).format()}
                                                    </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                        <i className="ni ni-money-coins" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                                                <span className={`text-${this.state.accumulatedLossesSwingtradeCurrentMonth - this.state.accumulatedLossesSwingtradeLastMonth >= 0 ? 'success' : 'danger'} mr-2`}>
                                                    <i className={`fa fa-arrow-${this.state.accumulatedLossesSwingtradeCurrentMonth - this.state.accumulatedLossesSwingtradeLastMonth >= 0 ? 'up' : 'down'}`} />
                                                    &nbsp;
                                                    {this.state.accumulatedLossesSwingtradeCurrentMonth
                                                        ? currency(((this.state.accumulatedLossesSwingtradeLastMonth - this.state.accumulatedLossesSwingtradeCurrentMonth) / this.state.accumulatedLossesSwingtradeCurrentMonth) * 100, {
                                                              separator: '',
                                                              decimal: ',',
                                                              symbol: '',
                                                          }).format()
                                                        : '-'}
                                                    %
                                                </span>{' '}
                                                <span className="text-nowrap">desde o mês passado</span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6" xl="3">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                                                        Prejuízos Day-Trade
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                                                        {currency(Number(this.state.accumulatedLossesDaytradeCurrentMonth), {
                                                            separator: '.',
                                                            decimal: ',',
                                                            symbol: 'R$ ',
                                                        }).format()}
                                                    </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                        <i className="ni ni-money-coins" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                                                <span className={`text-${this.state.accumulatedLossesDaytradeCurrentMonth - this.state.accumulatedLossesDaytradeLastMonth >= 0 ? 'success' : 'danger'} mr-2`}>
                                                    <i className={`fa fa-arrow-${this.state.accumulatedLossesDaytradeCurrentMonth - this.state.accumulatedLossesDaytradeLastMonth >= 0 ? 'up' : 'down'}`} />
                                                    &nbsp;
                                                    {this.state.accumulatedLossesDaytradeCurrentMonth
                                                        ? currency(((this.state.accumulatedLossesDaytradeLastMonth - this.state.accumulatedLossesDaytradeCurrentMonth) / this.state.accumulatedLossesSwingtradeCurrentMonth) * 100, {
                                                              separator: '',
                                                              decimal: ',',
                                                              symbol: '',
                                                          }).format()
                                                        : '-'}
                                                    %
                                                </span>{' '}
                                                <span className="text-nowrap">desde o mês passado</span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6" xl="3">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                                                        Prejuízos FIIS
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                                                        {currency(Number(this.state.accumulatedLossesFiisCurrentMonth), {
                                                            separator: '.',
                                                            decimal: ',',
                                                            symbol: 'R$ ',
                                                        }).format()}
                                                    </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                        <i className="ni ni-money-coins" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                                                <span className={`text-${this.state.accumulatedLossesFiisCurrentMonth - this.state.accumulatedLossesFiisLastMonth >= 0 ? 'success' : 'danger'} mr-2`}>
                                                    <i className={`fa fa-arrow-${this.state.accumulatedLossesFiisCurrentMonth - this.state.accumulatedLossesFiisLastMonth >= 0 ? 'up' : 'down'}`} />
                                                    &nbsp;
                                                    {this.state.accumulatedLossesFiisCurrentMonth
                                                        ? currency(((this.state.accumulatedLossesFiisLastMonth - this.state.accumulatedLossesFiisCurrentMonth) / this.state.accumulatedLossesFiisCurrentMonth) * 100, {
                                                              separator: '',
                                                              decimal: ',',
                                                              symbol: '',
                                                          }).format()
                                                        : '-'}
                                                    %
                                                </span>{' '}
                                                <span className="text-nowrap">desde o mês passado</span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6" xl="3">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                                                        Performance
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                                                        {this.state.performanceCurrentMonth
                                                            ? currency(((this.state.performanceLastMonth - this.state.performanceCurrentMonth) / this.state.performanceCurrentMonth) * 100, {
                                                                  separator: '',
                                                                  decimal: ',',
                                                                  symbol: '',
                                                              }).format()
                                                            : '-'}
                                                        %
                                                    </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                                                        <i className="ni ni-chart-bar-32" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">&nbsp;</p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <Container className="mt--6" fluid>
                    <Row>
                        <Col xl="8">
                            <Card className="bg-default">
                                <CardHeader className="bg-transparent">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h6 className="text-light text-uppercase ls-1 mb-1">Visão Geral</h6>
                                            <h5 className="h3 text-white mb-0">Resultado Acumulado</h5>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {this.state.resultsByMonthLabel.length && (
                                        <div className="chart" style={{ height: '37vh', width: '40vw' }}>
                                            <Chart
                                                type="bar"
                                                data={{
                                                    labels: this.state.resultsByMonthLabel ?? [],
                                                    datasets: [
                                                        {
                                                            label: 'Mensal',
                                                            data: this.state.resultsByMonthValues ?? [],
                                                            backgroundColor: 'rgb(54, 162, 235)',
                                                        },
                                                        {
                                                            label: 'Acumulado',
                                                            data: this.state.resultsByMonthValuesAccumulated ?? [],
                                                            backgroundColor: colors.success,
                                                        },
                                                    ],
                                                }}
                                                options={{
                                                    plugins: {
                                                        legend: {
                                                            labels: {
                                                                color: 'white',
                                                            },
                                                        },
                                                    },
                                                    scales: {
                                                        y: {
                                                            gridLines: {
                                                                color: '#525f7f',
                                                                zeroLineColor: '#525f7f',
                                                            },
                                                            ticks: {
                                                                color: 'white',
                                                                callback: function (value) {
                                                                    if (!(value % 10)) {
                                                                        return '' + value + 'k';
                                                                    }
                                                                },
                                                            },
                                                        },
                                                        x: {
                                                            ticks: {
                                                                color: 'white',
                                                            },
                                                        },
                                                    },
                                                    tooltips: {
                                                        callbacks: {
                                                            label: function (item, data) {
                                                                var label = data.datasets[item.datasetIndex].label || '';
                                                                var yLabel = item.yLabel;
                                                                var content = '';

                                                                if (data.datasets.length > 1) {
                                                                    content += label;
                                                                }

                                                                content += 'R$ ' + yLabel + 'k';
                                                                return content;
                                                            },
                                                        },
                                                    },
                                                }}
                                                id="chart-sales-dark"
                                                className="chart-canvas"
                                            />
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="4">
                            <Card>
                                <CardHeader className="bg-transparent">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h6 className="text-uppercase text-muted ls-1 mb-1">Sua carteira</h6>
                                            <h5 className="h3 mb-0">Composição de ativos</h5>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {this.state.chatWalletLabels.length && (
                                        <div className="chart" style={{ height: '37vh', width: '20vw' }}>
                                            <Pie
                                                data={{
                                                    labels: this.state.chatWalletLabels ?? [],
                                                    datasets: [
                                                        {
                                                            data: this.state.chatWalletData ?? [],
                                                            backgroundColor: [colors['danger'], colors['warning'], colors['success'], colors['primary'], colors['info']],
                                                            label: 'Dataset 1',
                                                        },
                                                    ],
                                                }}
                                                options={{
                                                    responsive: true,
                                                    legend: {
                                                        display: true,
                                                        position: 'top',
                                                    },
                                                    animation: {
                                                        animateScale: true,
                                                        animateRotate: true,
                                                    },
                                                }}
                                                className="chart-canvas"
                                                id="chart-pie"
                                            />
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Carteira - Posição atual</h3>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Ativo</th>
                                            <th scope="col">Quantidade</th>
                                            <th scope="col">Preço Médio</th>
                                            <th scope="col">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.wallet?.map((wallet, index) => (
                                            <tr key={index}>
                                                <th scope="row">{wallet.asset}</th>
                                                <td>{wallet.quantity}</td>
                                                <td>
                                                    {currency(Number(wallet.averagePriceBuy), {
                                                        separator: '.',
                                                        decimal: ',',
                                                        symbol: 'R$ ',
                                                    }).format()}
                                                </td>
                                                <td>
                                                    {currency(Number(wallet.totalValue), {
                                                        separator: '.',
                                                        decimal: ',',
                                                        symbol: 'R$ ',
                                                    }).format()}
                                                </td>
                                                <td></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default withRouter(Dashboard);
