import Select from "components/Select";
import React from "react";
// reactstrap components
import { Button, Card, CardBody, Form, Input, Modal } from "reactstrap";

const InitialCustodyDialog = () => {
  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={true}
      toggle={() => {}}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          Cadastrar ativo
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {}}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-4 py-lg-4">
            <Form role="form">
              <label
                className="form-control-label"
                htmlFor="validationCustom03"
              >
                Corretora
              </label>

              <Select
                defaultValue="1"
                options={[
                  { value: "1", label: "XP" },
                  { value: "2", label: "Badges" },
                  { value: "3", label: "Buttons" },
                  { value: "4", label: "Cards" },
                  { value: "5", label: "Forms" },
                  { value: "6", label: "Modals" },
                ]}
              />
              <label
                className="form-control-label mt-3"
                htmlFor="validationCustom03"
              >
                Ativo
              </label>

              <Select
                defaultValue="1"
                options={[
                  { value: "1", label: "PETR4" },
                  { value: "2", label: "Badges" },
                  { value: "3", label: "Buttons" },
                  { value: "4", label: "Cards" },
                  { value: "5", label: "Forms" },
                  { value: "6", label: "Modals" },
                ]}
              />

              <label
                className="form-control-label mt-3"
                htmlFor="validationCustom03"
              >
                Tipo
              </label>

              <Select
                defaultValue="1"
                options={[
                  { value: "1", label: "A vista/Leilão" },
                  { value: "2", label: "Badges" },
                  { value: "3", label: "Buttons" },
                  { value: "4", label: "Cards" },
                  { value: "5", label: "Forms" },
                  { value: "6", label: "Modals" },
                ]}
              />

              <label
                className="form-control-label"
                htmlFor="validationCustom03"
              >
                Quantidade
              </label>
              <Input
                defaultValue=""
                id="validationCustom03"
                placeholder=""
                required
                type="text"
                onChange={(e) => {}}
              />

              <label
                className="form-control-label"
                htmlFor="validationCustom03"
              >
                Preço Médio
              </label>
              <Input
                defaultValue="0,00"
                id="validationCustom03"
                placeholder=""
                required
                type="text"
                onChange={(e) => {}}
              />

              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Salvar
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};
export default InitialCustodyDialog;
